import React, { useState } from 'react';
import { generateId } from '../utils/genIds';
import DayPicker from '../utils/daypicker';

const DayPickerField = (props) => {
  const {
    label,
    description,
    errors = [],
    modelName,
    model,
    attribute,
    value,
    required,
    toolTip,
  } = props;

  const id = generateId();
  const name = `${modelName}[${attribute}]`;

  const [currentValue, setCurrentValue] = useState(value || model[attribute] || 1);
  const setCurrentDay = (date) => {
    const curr = date.split('-').pop().replace(/^0/, '')
    setCurrentValue(curr);
  };

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
        {!!required && '*'}
        {!!toolTip && <ToolTip dataForId={id} dataTip={toolTip} />}
      </label>
      {!!description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        <DayPicker currentValue={currentValue} onChange={(date) => setCurrentDay(date)} />
        <input
          id={id}
          type="hidden"
          name={name}
          value={currentValue}
        />

        {errors.length > 0 ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : (
          ''
        )}
      </div>
      {errors.map((e, i) => {
        return (
          <p key={i} className="mt-2 text-sm text-red-600">
            {e}
          </p>
        );
      })}
    </>
  );
};

export default DayPickerField;

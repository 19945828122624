import React, { Fragment, useRef, useState } from 'react'
import {
  Dialog, DialogPanel, DialogTitle,
  Transition, TransitionChild,
  Listbox, ListboxButton, ListboxOptions, ListboxOption, Label
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
// import Turbolinks from 'turbolinks'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const days = [...Array(28).keys()].map((i) => i + 1)

function Model({ isOpen, setOpen, actionUrl, paymentMethods, selectedPaymentMethod, setSelectedPaymentMethod }) {

  const cancelButtonRef = useRef(null)
  const [day, setDay] = useState(1)


  const handleClicked = (e) => {
    e.preventDefault()
    // todo: add the turbo visit
    // Turbolinks.visit()
  }


  return (
    <Transition as="div" show={isOpen}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {paymentMethods.length == 0 ? (
                  <p>
                    create a payment method.
                  </p>
                ) : (
                  <>
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 sm:mt-5">
                        <DialogTitle as="h3" className="text-lg text-center font-medium leading-6 text-gray-900">
                          Setup AutoPay
                        </DialogTitle>
                        <div>
                          <label htmlFor="charge_day" className="block text-sm font-medium leading-6 text-gray-900">
                            Day of the month to charge
                          </label>
                          <select
                            id="charge_day"
                            name="charge_day"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setDay(e.target.value)}
                            value={day}
                          >
                            {days.map((day) => <option key={day} value={day}>{day}</option>)}
                          </select>
                        </div>

                        <div className="mt-2">
                          <Dropdown
                            paymentMethods={paymentMethods}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <a
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        rel="nofollow"
                        data-method="POST"
                        onClick={handleClicked}
                        href={`${actionUrl}?payment_method=${selectedPaymentMethod.id}&autopay_day=${day}`}>
                        Create AutoPay
                      </a>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Close
                      </button>
                    </div>
                  </>
                )
                }
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
function Dropdown({ paymentMethods, selectedPaymentMethod, setSelectedPaymentMethod }) {

  return (
    <Listbox as="div" value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}>
      <Label className="block text-sm font-medium text-gray-700">Select the payment method you would like to be charged</Label>
      <div className="relative mt-1">

        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span className="block truncate">{`${selectedPaymentMethod.card.brand} - ending in ${selectedPaymentMethod.card.last4}`}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {paymentMethods.length > 0 ? (
              paymentMethods.map((pm, idx) => (
                <ListboxOption
                  key={idx}
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={pm}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {`${pm.card.brand} - ending in ${pm.card.last4}`}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}

                </ListboxOption>
              ))
            ) : ""}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  )
}

const AutoPayModal = (props) => {
  const { actionUrl, paymentMethods } = props;
  const [open, setOpen1] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0] || null)

  const setOpen = (val) => {
    setOpen1(val)
    // setTimeout(() => {
    //   Turbolinks.start()
    //   // ReactRailsUJS.detectEvents()
    // }, 1000)
  }


  return (
    <>
      <div>
        <button
          // className="mt-2 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          className=" inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => setOpen(true)}>Setup Auto Pay</button>
        <Model
          isOpen={open}
          setOpen={setOpen}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          paymentMethods={paymentMethods}
          actionUrl={actionUrl}
        />
      </div>
    </>
  );
};


export default AutoPayModal;

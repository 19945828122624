import React, { useState, useEffect, useRef } from 'react';
import { generateId } from '../utils/genIds';
import { ToolTip } from './FormInput';

// max file size in bytes (5MB)
const MAX_FILE_SIZE = 5000000;

const FileField = (props) => {
  const {
    label,
    initialValue,
    errors,
    name,
    hasMaxSize = true,
    buttonText = 'Change',
    previewType = 'image',
    acceptedTypes = [],
    toolTip,
  } = props;
  const id = generateId();
  const [image, setImage] = useState({ preview: initialValue, raw: '' });
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');
  const fileInput = useRef(null);

  useEffect(() => {
    if (initialValue) {
      setImage({ preview: initialValue, raw: '' });
    }
  }, []);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    if (hasMaxSize && e.target.files[0].size > MAX_FILE_SIZE) {
      setError('File is too large. Please resize image before uploading.');
      return;
    }

    if (!validateFileType(e.target.files[0])) {
      setError(`File type must be one of ${acceptedTypes.join(', ')}.`);
      return;
    }

    if (previewType === 'name') setFileName(e.target.files[0].name);

    if (!!error) setError('');

    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };

  const validateFileType = (file) => {
    if (!acceptedTypes.length) return true; // Any file type is valid if we don't pass an array of accepted types..

    return acceptedTypes.includes(file.name.split('.').pop());
  };

  const renderPreviewImage = () => {
    return image.preview ? (
      <img src={image.preview} className="w-auto h-32 mr-5" />
    ) : (
      <span className="flex justify-center items-center h-28 w-32 mr-5 overflow-hidden bg-gray-100">
        <svg
          className="h-12 w-12 text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  };

  const renderPreviewName = () => {
    return <span className="ml-2">{fileName}</span>;
  };

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
        {!!toolTip && <ToolTip dataForId={id} dataTip={toolTip} />}
        {hasMaxSize && (
          <p className="mt-1 mb-2 text-sm font-light leading-5 text-gray-500">
            Must be 5MB or smaller in size
          </p>
        )}
      </label>
      <div className="mt-2 flex items-center">
        {previewType === 'image' && renderPreviewImage()}

        <span className="rounded-md shadow-sm">
          <input
            ref={fileInput}
            type="file"
            name={name}
            id={id}
            style={{ display: 'none' }}
            onChange={handleChange}
          />
          <button
            onClick={() => handleClick()}
            type="button"
            className="py-2 px-3 border border-gray-300 bg-white rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          >
            {buttonText}
          </button>
        </span>

        {previewType === 'name' && renderPreviewName()}

        {errors.map((e, i) => {
          return (
            <p key={i} className="mt-2 text-sm text-red-600">
              {e}
            </p>
          );
        })}
      </div>
      {!!error && (
        <div>
          <p className="mt-2 text-sm text-red-600">{error}</p>
        </div>
      )}
    </>
  );
};

export default FileField;

import React, { useState, useContext, createContext, useReducer } from "react";
import { generateId } from "../../utils/genIds";
import DayPicker from "../../utils/daypicker";

const newScheduledReminder = {
  id: null,
  objectId: null,
  name: "",
  destroy: false,
  status: "active",
  days_before_or_after: 1,
};

const initialState = {
  fund: {},
  disableAddRemove: false,
  scheduledReminders: [],
};

const scheduledRemindersReducer = (state, action) => {
  switch (action.type) {
    case "ADD_SCHEDULED_REMINDER":
      return { ...state, scheduledReminders: [...state.scheduledReminders, action.scheduledReminder] };
    case "UPDATE_SCHEDULED_REMINDER":
      return {
        ...state, scheduledReminders: state.scheduledReminders.map((sr) => {
          if (sr.objectId === action.scheduledReminder.objectId) {
            return action.scheduledReminder
          } else {
            return sr
          }
        })
      };
    case "SET_SCHEDULED_REMINDERS":
      return { ...state, scheduledReminders: action.scheduledReminders };
    default:
      return state;
  }
};

const ScheduledRemindersContext = createContext(null);

const useScheduledReminders = () => {
  const [state, dispatch] = useContext(ScheduledRemindersContext);

  const addScheduledReminder = () => {
    dispatch({
      type: "ADD_SCHEDULED_REMINDER",
      scheduledReminder: { ...newScheduledReminder, objectId: generateId() },
    });
  };

  const changeDay = (scheduledReminder, date) => {
    const curr = date.split('-').pop().replace(/^0/, '')
    dispatch({
      type: "UPDATE_SCHEDULED_REMINDER",
      scheduledReminder: { ...scheduledReminder, days_before_or_after: curr },
    });
  };

  const removeScheduledReminder = (scheduledReminder) => {
    // const scheduledReminders = state.scheduledReminders
    // remove a reminder if it does exist
    // toggle destroy a reminder if it does exist
    if (scheduledReminder.id === null) {
      const newScheduledReminders = state.scheduledReminders.filter(
        (dl) => dl.objectId != scheduledReminder.objectId
      );
      dispatch({ type: "SET_SCHEDULED_REMINDERS", scheduledReminders: newScheduledReminders });
    } else {

      const newScheduledReminders = state.scheduledReminders.map((dl) => {
        if (dl.objectId === scheduledReminder.objectId) {
          return { ...dl, destroy: true };
        } else {
          return { ...dl };
        }
      });
      dispatch({ type: "SET_SCHEDULED_REMINDERS", scheduledReminders: newScheduledReminders });
    }
  };

  return { state, dispatch, changeDay, addScheduledReminder, removeScheduledReminder };
};

const ScheduledRemindersProvider = (props) => {
  // added  the form state
  const scheduledReminders = props.scheduledReminders.map((sr) => {
    if (sr.id) {
      return { ...sr, objectId: sr.id };
    } else {
      return { ...sr, objectId: generateId() };
    }
  });

  const [state, dispatch] = useReducer(scheduledRemindersReducer, {
    ...initialState,
    fund: props.fund,
    disableAddRemove: props.disableAddRemove || false,
    scheduledReminders: scheduledReminders || [],
    scheduledRemindersStatuses: props.scheduledRemindersStatuses
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <ScheduledRemindersContext.Provider value={value} {...props} />;
};

const ScheduledReminderFields = (props) => {
  const { removeScheduledReminder, changeDay, state } = useScheduledReminders();
  const { scheduledRemindersStatuses, disableAddRemove } = state;
  const { scheduledReminder, index } = props;
  const namePrefix = `fund[scheduled_reminders_attributes][${index}]`;

  const handleRemoveClick = (e) => {
    e.preventDefault();
    removeScheduledReminder(scheduledReminder);
  };

  return (
    <>
      {scheduledReminder.destroy ? (
        <>
          <input type="hidden" name={`${namePrefix}[_destroy]`} value="1" />
          <input type="hidden" name={`${namePrefix}[id]`} value={scheduledReminder.id} />
        </>
      ) : (
        <>
          {scheduledReminder.id ? (
            <input
              type="hidden"
              name={`${namePrefix}[id]`}
              value={scheduledReminder.id}
            />
          ) : (
            ""
          )}
          <input type="hidden" name={`${namePrefix}[reminder_type]`} value={scheduledReminder.reminder_type} />
          <input type="hidden" name={`${namePrefix}[delivery_method]`} value={scheduledReminder.delivery_method} />
          <input type="hidden" name={`${namePrefix}[interval]`} value={scheduledReminder.interval} />
          <input type="hidden" name={`${namePrefix}[status]`} value={scheduledReminder.status} />
          <div className="my-5 border border-gray-300 rounded-md overflow-hidden">
            <div className="grid grid-cols-8 gap-4 p-2 sm:p-3 bg-white">
              <div className="col-span-3 md:col-span-3">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Reminder Name
                </label>
                <input
                  name={`${namePrefix}[name]`}
                  defaultValue={scheduledReminder.name}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: Union Member"
                />
              </div>
              <div className="col-span-6 md:col-span-6">
              </div>

              <div className="col-span-3 md:col-span-3">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Custom Message
                </label>
                <textarea
                  name={`${namePrefix}[message]`}
                  defaultValue={scheduledReminder.message}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: Union Member"
                />
              </div>

              <div className="col-span-6 md:col-span-6">
              </div>

              <div className="col-span-3 md:col-span-3">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Days before or after
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Number days before(if it is a reminder) or after(if it is an overdue notification) the due date reminder is sent
                </p>
                <input
                  name={`${namePrefix}[days_before_or_after]`}
                  value={scheduledReminder.days_before_or_after}
                  type="hidden"
                />
                <DayPicker currentValue={scheduledReminder.days_before_or_after} onChange={(day) => { changeDay(scheduledReminder, day) }} />
              </div>

              {/* <div className="col-span-3">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Status
                </label>
                <select
                  name={`${namePrefix}[status]`}
                  defaultValue={scheduledReminder.status}
                  className="form-select mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                >
                  {scheduledRemindersStatuses.map((dl) => (
                    <option key={dl} value={dl}>
                      {dl}
                    </option>
                  ))}
                </select>
              </div> */}

              {disableAddRemove ? "" : (
                <div className="col-span-8 grid grid-cols-8 gap-4">
                  <p className="col-span-8 text-sm font-small leading-5 text-gray-500">
                    Note: The recurring schedule cannot be changed once saved.
                  </p>

                  <button
                    onClick={handleRemoveClick}
                    className="col-span-8 sm:col-span-2 px-2 py-3 disabled:bg-gray-200 disabled:text-red-600 disabled:cursor-not-allowed bg-gray-50 hover:bg-red-600 text-red-600 hover:text-white transition duration-150 border border-gray-200 text-xs rounded-md"
                  >
                    - Remove Reminder
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ScheduledRemindersContainer = () => {
  const { state } = useScheduledReminders();
  return (
    <>
      {state.scheduledReminders.map((scheduledReminder, index) => {
        return (
          <ScheduledReminderFields
            key={scheduledReminder.objectId}
            scheduledReminder={scheduledReminder}
            index={index}
          />
        );
      })}
    </>
  );
};

const AddScheduledReminder = () => {
  const { addScheduledReminder } = useScheduledReminders();

  const handleClick = (e) => {
    e.preventDefault();
    addScheduledReminder();
  };

  return (
    <>
      <span className="mt-3 inline-flex rounded-md shadow-sm">
        <button
          onClick={handleClick}
          className="relative inline-flex items-center py-2 px-4 bg-white hover:bg-indigo-500 border border-indigo-500 rounded-md text-sm leading-5 font-medium text-indigo-700 hover:text-white focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        >
          + Add Reminder
        </button>
      </span>
    </>
  );
};

const ScheduledRemindersForm = ({
  fund,
  disableAddRemove,
  scheduled_reminders: scheduledReminders,
  scheduled_reminders_statuses: scheduledRemindersStatuses,
  reminder_types: reminderTypes,
}) => {

  return (
    <>
      <ScheduledRemindersProvider
        fund={fund}
        disableAddRemove={disableAddRemove}
        scheduledReminders={scheduledReminders}
        scheduledRemindersStatuses={scheduledRemindersStatuses}
        reminderTypes={reminderTypes}
      >
        <div className="mt-8 flex justify-between">
          <div className="col-span-4 md:col-span-3">
            <h3 className="font-display text-xl font-medium leading-6 text-indigo-800">
              Scheduled Reminders.
            </h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              Cusomize the Dates and times you would like to send reminders for your members to pay their dues.
            </p>
          </div>
          <div className="col-span-2 md:col-span-1 flex justify-start md:justify-end">
            {disableAddRemove ? "" : <AddScheduledReminder />}
          </div>
        </div>

        <ScheduledRemindersContainer />
      </ScheduledRemindersProvider>
    </>
  );
};
export default ScheduledRemindersForm;

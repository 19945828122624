import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'
import { useForm } from './provider';

export const SuccessPage = (props) => {
  const { state } = useForm();
  const { fund, member, success_redirect_path } = state;
  return (
    <div className="text-center">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mx-auto w-36 h-36 text-green-500">
        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
      </svg>

      <h3 className="mt-2 text-sm font-medium text-gray-900">Success!</h3>
      <p className="mt-1 text-sm text-gray-500">Payment Was made Successfully.</p>
      <div className="mt-6">
        <a
          href={success_redirect_path}
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Back to Account
        </a>
      </div>
    </div>
  )
}

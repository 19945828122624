import React, { useState, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas'

export default (props) => {
  const sigCanvas = useRef();
  const [signature, setSignature] = useState("");
  const { label, required, modelName, attribute } = props;
  const handleChange = () => {
    setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))
  }


  const clear = (e) => {
    e.preventDefault()
    sigCanvas.current.clear()
  }
  return (
    <div className="py-2 sm:col-span-2">
      <label className="block text-sm leading-5 font-medium text-gray-700">
        {label}
        {required ? "*" : ""}
      </label>
      <div className="mt-1">
        <input type="hidden" name={`${modelName}[${attribute}]`} value={signature} />
        Signature Pad
        <SignatureCanvas
          ref={sigCanvas}
          onEnd={handleChange}
          penColor='black'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas border border-gray-300' }} />
        <button className="bg-gray-50 border rounded-md px-4 py-2 mt-2 hover:bg-gray-200" onClick={clear}>Clear</button>
      </div>
    </div >
  );
};
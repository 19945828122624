import React from "react";
import { InvoicePaymentFormProvider, useForm } from "./provider";
import { StripeSDKLoader } from "../../utils/StripeSdkLoader";
import { CardPaymentPage } from "./CardPaymentPage";
import { PickPaymentMethodPage } from "./PickPaymentMethodPage";
import { OfflinePaymentPage } from "./OfflinePaymentPage";
import { CreatePaymentMethodPage } from "./CreatePaymentMethodPage";
import { SuccessPage } from "./SuccessPage";
import StepWizard from "react-step-wizard";

const customAnimationClasses = {
  enterRight: '',
  enterLeft: '',
  exitRight: '',
  exitLeft: '',
  intro: ''
}
const InvoicePaymentFormPage = () => {

  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col min-h-1/2 items-center justify-center w-full max-w-2xl p-6 mx-auto mt-6 bg-white border border-gray-200 rounded-lg shadow overflow-hidden transition-height duration-500 ease-in-out transform">
          <div className="w-full">
            <StepWizard className="transition-height ease-in-out">
              <PickPaymentMethodPage stepName={"pick_payment_method"} />
              <CreatePaymentMethodPage stepName={"create_payment_method_page"} />
              <CardPaymentPage stepName={"card_payment_page"} />
              <OfflinePaymentPage stepName={"offline_payment_page"} />
              <SuccessPage stepName={"success_page"} />
            </StepWizard>
          </div>
        </div>
      </div>
    </>
  )
};

const InvoicePaymentForm = (props) => {
  const { stripe_publishable_key } = props
  return (
    <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
      <InvoicePaymentFormProvider {...props} >
        <InvoicePaymentFormPage />
      </InvoicePaymentFormProvider>
    </StripeSDKLoader>
  );
};

export default InvoicePaymentForm;

import React from 'react';
import { FormSettingsProvider, useFields } from './State';
import FormInput from '../FormInput';

export default (props) => {
  const { fund, form_setting, errors } = props;

  return (
    <FormSettingsProvider fund={fund} formSetting={form_setting}>
      <div className="mt-8 pt-8 border-t">
        <div>
          <h3 className="text-xl leading-6 font-medium text-indigo-800">
            Form Settings
          </h3>
          <p className="mt-1 mb-2 text-sm leading-5 text-gray-500">
            Configure your form with default fields and settings.
          </p>
        </div>

        <div className="grid grid-cols-8 gap-4">
          {!!form_setting.id && (
            <input
              type="hidden"
              name="fund[form_setting_attributes][id]"
              value={form_setting.id}
            />
          )}

          <div className="col-span-8 md:col-span-4">
            <FormInput
              label="Form Submit Text"
              value={form_setting.form_submit_text || ''}
              model={form_setting}
              modelName="fund[form_setting_attributes]"
              attribute="form_submit_text"
              toolTip="The text used for the submit button on the payment form and fund card. <br /> Leave this blank to use the default text."
              errors={errors['form_setting.form_submit_text']}
            />
          </div>

          <div className="grid grid-cols-8 gap-4 col-span-8">
            <AddAddressFields />
            {(fund.fund_type === 'dues' || fund.fund_type === 'misc_dues') && (
              <AddMembershipIdField />
            )}
          </div>
        </div>
      </div>
    </FormSettingsProvider>
  );
};

const AddAddressFields = () => {
  const {
    state,
    toggleAddressFields,
    toggleRequireAddressFields,
  } = useFields();
  const { formSetting } = state;

  const onIncludeAddress = (e) => {
    toggleAddressFields(e.target.checked);
  };

  const onRequireAddress = (e) => {
    toggleRequireAddressFields(e.target.checked);
  };

  return (
    <div className="col-span-8 md:col-span-4">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Address Fields
      </label>
      <div className="col-span-8 flex justify-between">
        <div className="flex items-center">
          <input
            type="hidden"
            name="fund[form_setting_attributes][include_address_fields]"
            value="false"
          />
          <input
            id="fund_include_address_fields"
            type="checkbox"
            name="fund[form_setting_attributes][include_address_fields]"
            checked={formSetting.include_address_fields}
            onChange={onIncludeAddress}
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="fund_include_address_fields"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Include address fields in form?
          </label>
        </div>
      </div>

      <input
        type="hidden"
        name="fund[form_setting_attributes][require_address_fields]"
        value="false"
      />
      {formSetting.include_address_fields && (
        <div className="col-span-8 flex justify-between">
          <div className="flex items-center">
            <input
              id="fund_require_address_fields"
              type="checkbox"
              name="fund[form_setting_attributes][require_address_fields]"
              checked={formSetting.require_address_fields}
              onChange={onRequireAddress}
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
            />
            <label
              htmlFor="fund_require_address_fields"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Require user fill in address fields?
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const AddMembershipIdField = () => {
  const {
    state,
    toggleMembershipIdField,
    toggleRequireMembershipIdField,
  } = useFields();
  const { formSetting } = state;

  const onIncludeMembershipId = (e) => {
    toggleMembershipIdField(e.target.checked);
  };

  const onRequireMembershipId = (e) => {
    toggleRequireMembershipIdField(e.target.checked);
  };

  return (
    <div className="col-span-8 md:col-span-4">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Membership ID Field
      </label>
      <div className="col-span-8 flex justify-between">
        <div className="flex items-center">
          <input
            type="hidden"
            name="fund[form_setting_attributes][include_membership_id_field]"
            value="false"
          />
          <input
            id="fund_include_membership_id_field"
            type="checkbox"
            name="fund[form_setting_attributes][include_membership_id_field]"
            checked={formSetting.include_membership_id_field}
            onChange={onIncludeMembershipId}
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="fund_include_membership_id_field"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Include Membership ID field in form?
          </label>
        </div>
      </div>

      <input
        type="hidden"
        name="fund[form_setting_attributes][require_membership_id_field]"
        value="false"
      />
      {formSetting.include_membership_id_field && (
        <div className="col-span-8 flex justify-between">
          <div className="flex items-center">
            <input
              id="fund_require_membership_id_field"
              type="checkbox"
              name="fund[form_setting_attributes][require_membership_id_field]"
              checked={formSetting.require_membership_id_field}
              onChange={onRequireMembershipId}
              className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
            />
            <label
              htmlFor="fund_require_membership_id_field"
              className="ml-2 block text-sm leading-5 text-gray-900"
            >
              Require user fill in Membership ID field?
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

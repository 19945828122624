import React from 'react';
import { ScheduledReportProvider, useFields, getTimeFromModel } from './State';

export default (props) => {
  const {
    model,
    modelName = 'scheduled_report',
    frequencyOptions,
    timezoneOptions,
    errors,
  } = props;

  return (
    <ScheduledReportProvider
      model={model}
      modelName={modelName}
      errors={errors}
    >
      <div className="mt-8 pt-8 border-t">
        <div className="grid grid-cols-8 gap-4 max-w-xl mx-auto">
          {!!model.id && (
            <input type="hidden" name={`${modelName}[id]`} value={model.id} />
          )}

          <TimeField />
          <FrequencyField frequencyOptions={frequencyOptions} />
          <DayField />
          <TimezoneField timezoneOptions={timezoneOptions} />
          <EmailField />
        </div>
      </div>
    </ScheduledReportProvider>
  );
};

const TimeField = () => {
  const { state, setTime } = useFields();
  const { model, modelName, hour, minute, ampm } = state;

  const onChangeTime = (e) => {
    const { name, value } = e.target;

    setTime(value, name);
  };

  return (
    <div className="col-span-8">
      <input
        type="hidden"
        name={`${modelName}[time]`}
        value={`${hour}:${minute} ${ampm}`}
      />

      <label className="block text-sm font-medium leading-5 text-gray-700">
        Time
      </label>
      <div className="col-span-8 flex justify-between">
        <div className="flex items-center w-full">
          <select
            id="hour-select"
            name="hour"
            defaultValue={getTimeFromModel('hour', model)}
            onChange={onChangeTime}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {new Array(12).fill(null).map((v, i) => {
              return (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              );
            })}
          </select>
          <span className="mx-1">:</span>
          <select
            id="minute-select"
            name="minute"
            defaultValue={getTimeFromModel('minute', model)}
            onChange={onChangeTime}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {new Array(12).fill(null).map((v, i) => {
              const value = `${i * 5}`.length < 2 ? `0${i * 5}` : `${i * 5}`;
              return (
                <option key={i} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          &nbsp;
          <select
            id="ampm-select"
            name="ampm"
            defaultValue={getTimeFromModel('ampm', model)}
            onChange={onChangeTime}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {['AM', 'PM'].map((v, i) => {
              return (
                <option key={i} value={v}>
                  {v}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

const FrequencyField = (props) => {
  const { state, setFrequency } = useFields();
  const { model, modelName } = state;
  const { frequencyOptions } = props;

  const onChangeFrequency = (e) => {
    const { value } = e.target;

    setFrequency(value);
  };

  return (
    <div className="col-span-8">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Frequency
      </label>
      <div className="flex items-center">
        <select
          id="frequency-select"
          name={`${modelName}[frequency]`}
          defaultValue={model.frequency}
          onChange={onChangeFrequency}
          className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        >
          {frequencyOptions.map((v, i) => {
            return (
              <option key={i} value={v}>
                {v}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const DayField = () => {
  const { state, setDay } = useFields();
  const { model, modelName } = state;

  const onChangeDay = (e) => {
    const { value } = e.target;

    setDay(value);
  };

  if (state.frequency === 'daily') return null;

  return (
    <div className="col-span-8">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Day of week
      </label>
      <div className="flex items-center">
        <select
          id="frequency-select"
          name={`${modelName}[day]`}
          defaultValue={model.day}
          onChange={onChangeDay}
          className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        >
          {DAYS.map((v, i) => {
            return (
              <option key={i} value={v}>
                {v}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const TimezoneField = (props) => {
  const { state, setTimeZone } = useFields();
  const { model, modelName } = state;
  const { timezoneOptions } = props;

  const onChangeTimezone = (e) => {
    const { value } = e.target;

    setTimeZone(value);
  };

  return (
    <div className="col-span-8">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Timezone
      </label>
      <div className="flex items-center">
        <select
          id="timezone-select"
          name={`${modelName}[timezone]`}
          defaultValue={model.timezone}
          onChange={onChangeTimezone}
          className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        >
          {timezoneOptions.map((v, i) => {
            return (
              <option key={i} value={v}>
                {v}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const EmailField = () => {
  const { state, setemail } = useFields();
  const { model, modelName, errors } = state;

  const onChangeEmail = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  return (
    <div className="col-span-8">
      <label className="block text-sm font-medium leading-5 text-gray-700">
        Email
      </label>
      <div className="flex items-center">
        <input
          type="text"
          id="email-input"
          name={`${modelName}[email]`}
          defaultValue={model.email}
          className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        />
      </div>
      {!!errors.email && (
        <div className="text-sm text-red-600">{errors.email}</div>
      )}
    </div>
  );
};

import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const StripeContext = createContext(null);

const initialState = {
  model: {
    name: '',
    email: '',
  },
  appliedCoupon: {},
  paymentMethod: '',
  loading: false,
  couponLoading: false,
  error: '',
};

export const StripeFormProvider = (props) => {
  const { errors, model } = props;
  const state = {
    ...initialState,
    model,
    errors,
  };

  const [store, dispatch] = useReducer(stripeFormReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <StripeContext.Provider value={value} {...props} />;
};

const stripeFormReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MODEL':
      return { ...state, model: action.model };
    case 'TOGGLE_LOADING':
      return { ...state, loading: !state.loading };
    case 'TOGGLE_COUPON_LOADING':
      return { ...state, couponLoading: !state.couponLoading };
    case 'TOGGLE_COMPLETED':
      return { ...state, completed: !state.completed };
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'SET_ERRORS':
      return { ...state, errors: action.errors };
    case 'SET_PAYMENT_METHOD':
      return { ...state, paymentMethod: action.paymentMethod };
    case 'SET_COUPON':
      return { ...state, coupon: action.coupon };
    case 'SET_APPLIED_COUPON':
      return { ...state, appliedCoupon: action.appliedCoupon };
    default:
      return state;
  }
};

export const useForm = () => {
  const [state, dispatch] = useContext(StripeContext);

  const updateModel = useCallback(
    (attribute, value) => {
      const model = { ...state.model };
      model[attribute] = value;
      dispatch({ type: 'UPDATE_MODEL', model });
    },
    [state, dispatch]
  );

  return {
    state,
    dispatch,
    stripe: useStripe(),
    elements: useElements(),
    updateModel,
  };
};

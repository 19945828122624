import React from "react";

const days = [
  // { date: '2021-12-27' },
  // { date: '2021-12-28' },
  // { date: '2021-12-29' },
  // { date: '2021-12-30' },
  // { date: '2021-12-31' },
  { date: '2022-01-01', isCurrentMonth: true },
  { date: '2022-01-02', isCurrentMonth: true },
  { date: '2022-01-03', isCurrentMonth: true },
  { date: '2022-01-04', isCurrentMonth: true },
  { date: '2022-01-05', isCurrentMonth: true },
  { date: '2022-01-06', isCurrentMonth: true },
  { date: '2022-01-07', isCurrentMonth: true },
  { date: '2022-01-08', isCurrentMonth: true },
  { date: '2022-01-09', isCurrentMonth: true },
  { date: '2022-01-10', isCurrentMonth: true },
  { date: '2022-01-11', isCurrentMonth: true },
  { date: '2022-01-12', isCurrentMonth: true },
  { date: '2022-01-13', isCurrentMonth: true },
  { date: '2022-01-14', isCurrentMonth: true },
  { date: '2022-01-15', isCurrentMonth: true },
  { date: '2022-01-16', isCurrentMonth: true },
  { date: '2022-01-17', isCurrentMonth: true },
  { date: '2022-01-18', isCurrentMonth: true },
  { date: '2022-01-19', isCurrentMonth: true },
  { date: '2022-01-20', isCurrentMonth: true },
  { date: '2022-01-21', isCurrentMonth: true },
  { date: '2022-01-22', isCurrentMonth: true },
  { date: '2022-01-23', isCurrentMonth: true },
  { date: '2022-01-24', isCurrentMonth: true },
  { date: '2022-01-25', isCurrentMonth: true },
  { date: '2022-01-26', isCurrentMonth: true },
  { date: '2022-01-27', isCurrentMonth: true },
  { date: '2022-01-28', isCurrentMonth: true },
  // { date: '2022-01-29', isCurrentMonth: true },
  // { date: '2022-01-30', isCurrentMonth: true },
  // { date: '2022-01-31', isCurrentMonth: true },
  // { date: '2022-02-01' },
  // { date: '2022-02-02' },
  // { date: '2022-02-03' },
  // { date: '2022-02-04' },
  // { date: '2022-02-05' },
  // { date: '2022-02-06' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DayPicker = ({ onChange, currentValue }) => {

  return (
    <div className="">
      <div className="isolate flex flex-auto bg-white">
        <div className=" w-full max-w-md flex-none md:block">
          <div className="hidden mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => {
              const isSelected = day.date.split('-').pop().replace(/^0/, '') === currentValue.toString()
              return (
                <button
                  key={day.date}
                  type="button"
                  onClick={() => onChange(day.date)}
                  className={classNames(
                    'py-1.5 hover:bg-gray-100 focus:z-10',
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (isSelected || day.isToday) && 'font-semibold',
                    isSelected && 'text-white',
                    !isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                    !isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                    day.isToday && !isSelected && 'text-indigo-600',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === days.length - 7 && 'rounded-bl-lg',
                    dayIdx === days.length - 1 && 'rounded-br-lg'
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                      isSelected && day.isToday && 'bg-indigo-600',
                      isSelected && !day.isToday && 'bg-gray-900'
                    )}
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DayPicker;
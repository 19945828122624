import React, { useState, useRef, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import axios from "../utils/requests";

const OrgSwitcher = (props) => {
  const { current_organization, organizations } = props;
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [filteredOrgs, setFilteredOrgs] = useState(organizations);

  const handleClickOutside = (e) => {
    if (containerRef.current.contains(e.target)) return;

    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  return (
    <>
      {organizations.length > 1 ? (
        <div className="relative" ref={containerRef}>
          <button
            onClick={() => setOpen(!open)}
            className="font-body group flex items-center w-full px-2 py-2 text-sm leading-5 font-medium text-gray-600 transition ease-in-out duration-150 focus:outline-none focus:bg-gray-100 hover:bg-gray-50 hover:text-gray-900"
          >
            <svg
              className="flex-shrink-0 h-6 w-6 ml-2 mr-4 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            {current_organization.name}
          </button>
          {open ? (
            <div className="absolute z-20 w-full transform px-2 sm:px-0">
              <div className="shadow-lg">
                <div className="ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="z-20 relative w-full grid py-1 bg-white">
                    <SearchForm setFilteredOrgs={setFilteredOrgs} />
                    <OrgList organizations={filteredOrgs} current_organization={current_organization} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <h3 className="w-full flex items-center text-left p-2 ml-3 text-sm font-body leading-5 font-normal text-gray-600">
          {current_organization.name}
        </h3>
      )}
    </>
  );
};


const OrgList = (props) => {
  const { organizations, current_organization } = props;
  return (
    <>
      {organizations.map((org, index) => {
        return org.id != current_organization.id && (
          <a
            key={org.id}
            href={`/dashboard/${org.id}/update_org`}
            data-method="post"
            className="mx-3 mt-2 p-2 border-gray-300 hover:bg-gray-100 rounded-md transition ease-in-out duration-150"
          >
            <p className="text-base leading-6 font-medium text-indigo-600"> {org.name} </p>
          </a>
        )
      })
      }
    </>
  )
}


const SearchForm = ({ setFilteredOrgs }) => {
  const [search, setSearch] = useState('')
  const searchRef = useRef(null);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (search.length < 2) return;
      axios
        .get(`/dashboard/organizations?search_value=${search}`)
        .then((response) => {
          setFilteredOrgs(response.data)
        });
    }, 500)

    return () => clearTimeout(getData)
  }, [search]);

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <form onSubmit={handleSubmit} className=" flex space-x-4 m-3" >
      <div className="min-w-0 flex-1">
        <label htmlFor="org_search" className="sr-only">
          Search
        </label>
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="search"
            name="search"
            ref={searchRef}
            value={search}
            id="org_search"
            onChange={e => setSearch(e.target.value)}
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Search"
          />
        </div>
      </div>
    </form>
  )

}

export default OrgSwitcher;

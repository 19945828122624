import React, { useState, useContext, createContext, useReducer } from "react";
import { generateId } from "../utils/genIds";

const newProductVariant = {
  id: null,
  objectId: null,
  name: "",
  description: "",
  price: 10,
  quantity: 0,
  destroy: false,
};

const initialState = {
  product: {},
  product_variants: [],
};

const productVariantsReducer = (state, action) => {
  switch (action.type) {
    case "ADD_PRODUCT_VARIANT":
      return {
        ...state,
        productVariants: [...state.productVariants, action.productVariant],
      };
    case "SET_PRODUCT_VARIANTS":
      return { ...state, productVariants: action.productVariants };
    default:
      return state;
  }
};

const ProductVariantsContext = createContext(null);

const useProductVariants = () => {
  const [state, dispatch] = useContext(ProductVariantsContext);

  const addProductVariant = () => {
    dispatch({
      type: "ADD_PRODUCT_VARIANT",
      productVariant: { ...newProductVariant, objectId: generateId() },
    });
  };

  const removeProductVariant = (productVariant) => {
    // const productVariants = state.productVariants
    // remove a product variant if it does exist
    // toggle destroy a product variant if it does exist
    if (productVariant.id === null) {
      const newProductVariants = state.productVariants.filter(
        (dl) => dl.objectId != productVariant.objectId
      );
      dispatch({
        type: "SET_PRODUCT_VARIANTS",
        productVariants: newProductVariants,
      });
    } else {
      const newProductVariants = state.productVariants.map((dl) => {
        if (dl.objectId === productVariant.objectId) {
          return { ...dl, destroy: true };
        } else {
          return { ...dl };
        }
      });
      dispatch({
        type: "SET_PRODUCT_VARIANTS",
        productVariants: newProductVariants,
      });
    }
  };

  return { state, dispatch, addProductVariant, removeProductVariant };
};

const ProductVariantsProvider = (props) => {
  // added  the form state

  const [state, dispatch] = useReducer(productVariantsReducer, {
    ...initialState,
    product: props.product,
    productVariants: props.productVariants || [],
    productVariantStatuses: props.productVariantStatuses,
    intervalOptions: props.intervalOptions,
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <ProductVariantsContext.Provider value={value} {...props} />;
};

const ProductVariantFields = (props) => {
  const { removeProductVariant, state } = useProductVariants();
  const { productVariant, index } = props;
  const namePrefix = `product[product_variants_attributes][${index}]`;

  const handleRemoveClick = (e) => {
    e.preventDefault();
    removeProductVariant(productVariant);
  };

  const disabled = !!productVariant.id && !productVariant.editable;

  return (
    <>
      {productVariant.destroy ? (
        <>
          <input type="hidden" name={`${namePrefix}[_destroy]`} value="1" />
          <input
            type="hidden"
            name={`${namePrefix}[id]`}
            value={productVariant.id}
          />
        </>
      ) : (
        <>
          {productVariant.id ? (
            <input
              type="hidden"
              name={`${namePrefix}[id]`}
              value={productVariant.id}
            />
          ) : (
            ""
          )}
          <div className="shadow sm:rounded-md sm:overflow-hidden my-5">
            <div className="grid grid-cols-8 gap-4 p-2 sm:p-3 bg-white">
              <div className="col-span-8 md:col-span-4">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Variant Name
                </label>
                <input
                  name={`${namePrefix}[name]`}
                  defaultValue={productVariant.name}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: Small, large"
                />
              </div>

              <div className="col-span-8 md:col-span-2">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Price
                </label>
                <input
                  name={`${namePrefix}[price]`}
                  defaultValue={productVariant.price}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: $70.00"
                />
              </div>
              <div className="col-span-8 md:col-span-2">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Quantity
                </label>
                <input
                  name={`${namePrefix}[quantity]`}
                  defaultValue={productVariant.quantity}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: 70"
                />
              </div>

              <div className="col-span-8 flex justify-between">
                <div className="flex items-center">

                  <input
                    type="hidden"
                    name={`${namePrefix}[unlimited_quantity]`}
                    value="false"
                  />
                  <input
                    id={`${productVariant.objectId}UnlimitedQuantity`}
                    type="checkbox"
                    name={`${namePrefix}[unlimited_quantity]`}
                    defaultChecked={productVariant.unlimited_quantity}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor={`${productVariant.objectId}UnlimitedQuantity`}
                    className="ml-2 block text-sm leading-5 text-gray-900"
                  >
                    Unlimited Quantity
                  </label>
                </div>

                <div className="">
                  {disabled ? (
                    <button
                      onClick={handleRemoveClick}
                      disabled={disabled}
                      className="col-span-8 sm:col-span-2 px-2 py-3 cursor-not-allowed bg-gray-50 text-red-600 transition duration-150 border border-gray-200 text-xs rounded-md"
                    >
                      - Remove Product Variant
                    </button>
                  ) : (
                    <button
                      onClick={handleRemoveClick}
                      disabled={disabled}
                      className="col-span-8 sm:col-span-2 px-2 py-3 disabled:bg-gray-200 disabled:text-red-600 disabled:cursor-not-allowed bg-gray-50 hover:bg-red-600 text-red-600 hover:text-white transition duration-150 border border-gray-200 text-xs rounded-md"
                    >
                      - Remove Product Variant
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ProductVariantsContainer = () => {
  const { state } = useProductVariants();
  return (
    <>
      {state.productVariants.map((productVariant, index) => {
        return (
          <ProductVariantFields
            key={productVariant.objectId}
            productVariant={productVariant}
            index={index}
          />
        );
      })}
    </>
  );
};

const AddProductVariant = () => {
  const { addProductVariant } = useProductVariants();

  const handleClick = (e) => {
    e.preventDefault();
    addProductVariant();
  };

  return (
    <>
      <span className="mt-3 inline-flex rounded-md shadow-sm">
        <button
          onClick={handleClick}
          className="relative inline-flex items-center py-2 px-4 bg-white hover:bg-indigo-500 border border-indigo-500 rounded-md text-sm leading-5 font-medium text-indigo-700 hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        >
          + Add Variant
        </button>
      </span>
    </>
  );
};

const NestedProductVariantsFields = ({
  product,
  productVariants,
  productVariantStatuses,
  intervalOptions,
}) => {
  return (
    <>
      <ProductVariantsProvider
        product={product}
        productVariants={productVariants}
        productVariantStatuses={productVariantStatuses}
        intervalOptions={intervalOptions}
      >
        <div className="mt-8 pt-8 border-t grid grid-cols-6">
          <div className="col-span-6 sm:col-span-5">
            <h3 className="font-display text-xl font-medium leading-6 text-indigo-800">
              Product Variants
            </h3>
            <p className="mt-1 mb-2 text-sm leading-5 text-gray-500">
              add Variants of your products. for instance, if you are selling a
              t-shirt, this is where you add the sizes and quantities of each
              size.
            </p>
          </div>
          <div className="col-span-2 sm:col-span-1 flex">
            <AddProductVariant />
          </div>
        </div>

        <ProductVariantsContainer />
      </ProductVariantsProvider>
    </>
  );
};
export default NestedProductVariantsFields;

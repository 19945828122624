import React from 'react';
import { useFields } from './State';

const Option = (props) => {
  const { removeOption, state } = useFields();
  const { dropdownOption, index, fieldIndex } = props;
  const namePrefix = `${state.resource_name}[form_fields_attributes][${fieldIndex}][form_select_options_attributes][${index}]`;

  const handleRemoveClick = (e) => {
    e.preventDefault();
    removeOption(dropdownOption);
  };

  return (
    <>
      {dropdownOption.destroy ? (
        <>
          <input type="hidden" name={`${namePrefix}[_destroy]`} value="1" />
          <input
            type="hidden"
            name={`${namePrefix}[id]`}
            value={dropdownOption.id}
          />
        </>
      ) : (
        <>
          {dropdownOption.id ? (
            <input
              type="hidden"
              name={`${namePrefix}[id]`}
              value={dropdownOption.id}
            />
          ) : (
            ''
          )}
          <div className="border sm:rounded-md sm:overflow-hidden my-5">
            <div className="grid grid-cols-8 gap-4 p-2 sm:p-3 bg-white">
              <div className="col-span-6">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Option Value
                </label>
                <input
                  autoFocus={!dropdownOption.id}
                  name={`${namePrefix}[value]`}
                  defaultValue={dropdownOption.value}
                  disabled={dropdownOption.disable_change}
                  placeholder="I heard about this from a social media post"
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                />
              </div>

              <button
                onClick={handleRemoveClick}
                className="col-span-2 px-2 py-2 mt-auto bg-gray-50 hover:bg-red-600 text-red-600 hover:text-white transition duration-150 border border-gray-200 text-xs rounded-md"
              >
                - Remove option
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const DropDownOptions = (props) => {
  const { addOption, state } = useFields();
  const { field, fieldIndex } = props;

  const options = state.dropdown_options.filter(
    (o) => o?.form_field_id === field.id || o?.fieldObjectId == field.objectId
  );

  const handleAddOption = (e) => {
    e.preventDefault();
    addOption(field);
  };

  return (
    <div className="col-span-8">
      <hr />
      <div className="flex justify-between items-center mt-5">
        <span className="text-sm text-gray-600">
          Configure dropdown options below
        </span>

        <span className="inline-flex rounded-md shadow-sm">
          <button
            onClick={handleAddOption}
            className="relative inline-flex items-center py-2 px-4 bg-white hover:bg-indigo-500 border border-indigo-500 rounded-md text-sm leading-5 font-medium text-indigo-700 hover:text-white focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          >
            + Add Option
          </button>
        </span>
      </div>
      {options.length > 0 &&
        options.map((o, idx) => (
          <Option
            key={idx}
            index={idx}
            fieldIndex={fieldIndex}
            dropdownOption={o}
          />
        ))}
    </div>
  );
};

import React, { useCallback, useState, useMemo, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { generateId } from '../../utils/genIds';

const Container = (props) => {
  const id = generateId();
  const isActive = canDrop && isOver
  const fileInput = useRef(null);
  const { buttonText, name, acceptedTypes } = props
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');

  const validateFileType = (file) => {
    if (!acceptedTypes.length) return true; // Any file type is valid if we don't pass an array of accepted types..
    return acceptedTypes.includes(file.name.split('.').pop());
  };

  const [droppedFiles, setDroppedFiles] = useState([])

  const handleFileDrop = useCallback(
    (item) => {
      if (item) {
        const files = item.files
        fileInput.current.files = item.dataTransfer.files
        setFileName(files[0].name)
        setDroppedFiles(files)
      }
    },
    [setDroppedFiles],
  )

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        handleFileDrop(item)
      },
      canDrop(item) {
        // console.log('canDrop', item.files, item.items)
        return true
      },
      hover(item) {
        // console.log('hover', item.files, item.items)
      },
      collect: (monitor) => {
        const item = monitor.getItem()
        if (item) {
          // console.log('collect', item.files, item.items)
        }
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [props],
  )


  const handleClick = () => {
    fileInput.current.click();
  };

  // max file size in bytes (10MB)
  const MAX_FILE_SIZE = 10000000;
  const handleChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setError('File is too large. Please resize image before uploading.');
      return;
    }

    if (!validateFileType(e.target.files[0])) {
      setError(`File type must be one of ${acceptedTypes.join(', ')}.`);
      return;
    }
    setDroppedFiles(e.target.files)
    // setDroppedFiles(fileInput.current.files)

    setFileName(e.target.files[0].name);


    // if (previewType === 'name') setFileName(e.target.files[0].name);

    // if (!!error) setError('');

    // setImage({
    //   preview: URL.createObjectURL(e.target.files[0]),
    //   raw: e.target.files[0],
    // });
  };


  return (
    <>
      <input
        ref={fileInput}
        type="file"
        name={name}
        id={id}
        style={{ display: 'none' }}
        onChange={handleChange}
      />

      <button
        ref={drop}
        type="button"
        onClick={handleClick}
        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
          />
        </svg>
        <span className="mt-2 block text-sm font-medium text-gray-900"> {isActive ? 'Release to drop' : buttonText} </span>
      </button>

      <div>{fileName}</div>

      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
    </>
  )
}

function list(files) {
  const label = (file) =>
    `'${file.name}' of size '${file.size}' and type '${file.type}'`
  return files.map((file) => <li key={file.name}>{label(file)}</li>)
}

const FileList = ({ files }) => {
  if (files.length > 0) {
    const fileList = useMemo(() => list(files), [files])
    return <div>{fileList}</div>
  } else {
    return <div></div>
  }
}


export default Container
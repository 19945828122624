import React, { useContext, createContext, useReducer } from 'react';

const initialState = {
  hour: '',
  minute: '',
  ampm: '',
  day: 'Friday',
  frequency: 'daily',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const formSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_HOUR':
      return {
        ...state,
        hour: action.hour,
      };
    case 'SET_MINUTE':
      return {
        ...state,
        minute: action.minute,
      };
    case 'SET_AMPM':
      return {
        ...state,
        ampm: action.ampm,
      };
    case 'SET_DAY':
      return {
        ...state,
        day: action.day,
      };
    case 'SET_FREQUENCY':
      return {
        ...state,
        frequency: action.frequency,
      };
    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.timezone,
      };
    default:
      return state;
  }
};

const ScheduledReportContaxt = createContext(null);

export const useFields = () => {
  const [state, dispatch] = useContext(ScheduledReportContaxt);

  //
  // "property" param can be one of "hour", "minute", "ampm"
  const setTime = (val, property) => {
    dispatch({
      type: `SET_${property.toUpperCase()}`,
      [property]: val,
    });
  };

  const setDay = (day) => {
    dispatch({
      type: 'SET_DAY',
      day,
    });
  };

  const setFrequency = (frequency) => {
    dispatch({
      type: 'SET_FREQUENCY',
      frequency,
    });
  };

  const setTimeZone = (timezone) => {
    dispatch({
      type: 'SET_TIMEZONE',
      timezone,
    });
  };

  return {
    state,
    dispatch,
    setTime,
    setDay,
    setFrequency,
    setTimeZone,
    getTimeFromModel,
  };
};

export const getTimeFromModel = (method, model) => {
  const time = model.time.replace(':', ' ').split(' ');

  switch (method) {
    case 'hour':
      return time[0];
    case 'minute':
      return time[1];
    case 'ampm':
      return time[2];
    default:
      return model.time;
  }
};

export const ScheduledReportProvider = (props) => {
  const { model, modelName, errors } = props;

  const [state, dispatch] = useReducer(formSettingsReducer, {
    ...initialState,
    model,
    modelName,
    errors,
    hour: getTimeFromModel('hour', model),
    minute: getTimeFromModel('minute', model),
    ampm: getTimeFromModel('ampm', model),
    frequency: model.frequency,
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <ScheduledReportContaxt.Provider value={value} {...props} />;
};

// Used for the address country select field
export const COUNTRY_SELECT_OPTIONS = [
  { text: 'United States', value: 'US' },
  { text: 'Australia', value: 'AU' },
  { text: 'Austria', value: 'AT' },
  { text: 'Belgium', value: 'BE' },
  { text: 'Bulgaria', value: 'BG' },
  { text: 'Brazil ', value: 'BR' },
  { text: 'Canada', value: 'CA' },
  { text: 'Cyprus', value: 'CY' },
  { text: 'Czech Republic', value: 'CZ' },
  { text: 'Denmark', value: 'DK' },
  { text: 'Estonia', value: 'EE' },
  { text: 'Finland', value: 'FI' },
  { text: 'France', value: 'FR' },
  { text: 'Germany', value: 'DE' },
  { text: 'Greece', value: 'GR' },
  { text: 'Hong Kong', value: 'HK' },
  { text: 'India', value: 'IN' },
  { text: 'Ireland', value: 'IE' },
  { text: 'Italy', value: 'IT' },
  { text: 'Japan', value: 'JP' },
  { text: 'Latvia', value: 'LV' },
  { text: 'Lithuania', value: 'LT' },
  { text: 'Luxembourg', value: 'LU' },
  { text: 'Malaysia', value: 'MY' },
  { text: 'Malta', value: 'MT' },
  { text: 'Mexico ', value: 'MX' },
  { text: 'Netherlands', value: 'NL' },
  { text: 'New Zealand', value: 'NZ' },
  { text: 'Norway', value: 'NO' },
  { text: 'Poland', value: 'PL' },
  { text: 'Portugal', value: 'PT' },
  { text: 'Romania', value: 'RO' },
  { text: 'Singapore', value: 'SG' },
  { text: 'Slovakia', value: 'SK' },
  { text: 'Slovenia', value: 'SI' },
  { text: 'Spain', value: 'ES' },
  { text: 'Sweden', value: 'SE' },
  { text: 'Switzerland', value: 'CH' },
  { text: 'United Kingdom', value: 'GB' },
];

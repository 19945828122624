import React, { useContext, useState } from "react";
import { Switch, Field, Description, Transition } from '@headlessui/react'
import { FormContext } from "./CreateInvoiceFundForm";
import { useStore } from 'zustand'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const ReminderForm = (props) => {
  const { reminder, index } = props;
  const [daysBeforeOrAfter, setDaysBeforeOrAfter] = useState(reminder.days_before_or_after);
  return (
    <div className="p-3 border rounded border-gray-300 my-8 relative">
      {index === 0 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Reminder 1</p>}
      {index === 1 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Reminder 2</p>}
      {index === 2 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Overdue Reminder 1</p>}
      {reminder.id && <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][id]`} value={reminder.id} />}
      {['name', 'reminder_type', 'interval', 'delivery_method', 'status', 'time_of_day', 'time_zone', 'email_subject'].map((field) => (
        <input key={field} type="hidden" name={`fund[scheduled_reminders_attributes][${index}][${field}]`} value={reminder[field]} />
      ))}
      <p className="text-gray-400 font-medium">
        {index === 0 && (<> The first <span className="text-gray-900"> reminder </span> be sent out </>)}
        {index === 1 && (<>  Another <span className="text-gray-900"> reminder </span> will be sent out </>)}
        {index === 2 && (<> And an <span className="text-gray-900"> overdue </span> notice will be sent out </>)}
        <select
          name={`fund[scheduled_reminders_attributes][${index}][days_before_or_after]`}
          className="text-gray-900 border-0 border-b"
          value={daysBeforeOrAfter}
          onChange={(e) => setDaysBeforeOrAfter(e.target.value)}>
          {Array.from(Array(28).keys()).map((i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
        {index === 0 && ("day(s) before the due date.")}
        {index === 1 && ("day(s) before the due date.")}
        {index === 2 && ("day(s) after the due date.")}
      </p>

      <div className="grid grid-cols-2">
        <div className="sm:col-span-1">
          <label className="block text-sm font-medium leading-5 text-gray-700">
            Message
          </label>
          <p className="mt-1 text-sm text-gray-500">
            Add a short message that will appear on the email
          </p>
          <div className="mt-1 relative rounded-md shadow-sm">
            <textarea
              name={`fund[scheduled_reminders_attributes][${index}][message]`}
              defaultValue={reminder.message}
              className="form-input block w-full focus:ring-indigo-500  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ToggleAutoReminders = ({ autoRemind, setAutoRemind }) => {

  return (
    <>
      <h3 className="pt-6 text-xl font-medium text-indigo-800">Communication Schedule</h3>
      <Field as="div" className="flex items-center justify-between">
        <span className="flex flex-grow flex-col">
          <Description as="span" className="text-sm text-gray-500">
            This feature automatically sends emails to remind members about their dues.
          </Description>
        </span>
        <input type="hidden" name="fund[auto_remind]" value={autoRemind} />
        <Switch
          checked={autoRemind}
          onChange={setAutoRemind}
          className={classNames(
            autoRemind ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              autoRemind ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </Field>
    </>
  )
};

const ToggleAutoInvoicing = ({ autoInvoice, setAutoInvoice }) => {

  return (
    <Field as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Description as="span" className="text-sm text-gray-500">
          This feature automatically generates invoices for this membership type.
        </Description>
      </span>
      <input type="hidden" name="fund[auto_invoice]" value={autoInvoice} />
      <Switch
        checked={autoInvoice}
        onChange={setAutoInvoice}
        className={classNames(
          autoInvoice ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            autoInvoice ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Field>
  )
}

const InvoiceScheduleForm = (props) => {
  const store = useContext(FormContext);
  const { invoicingFund, dueDayOptions, scheduledReminders, setAutoRemind, setAutoInvoice } = useStore(store);
  const [dueDay, setDueDay] = useState(invoicingFund.due_day);
  const autoRemind = invoicingFund.auto_remind;
  const autoInvoice = invoicingFund.auto_invoice;
  return (
    <div className="">
      <h3 className="text-xl mt-4 font-medium text-indigo-800">Enable Autopay</h3>
      <ToggleAutoInvoicing autoInvoice={autoInvoice} setAutoInvoice={setAutoInvoice} />
      <div className="">
        <div className="overflow-hidden p-1">
          <Transition
            as="div"
            show={autoInvoice}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >
            <div className="mt-4 p-4 bg-slate-50 rounded-md">
              <h3 className="text-md font-medium ">Billing Schedule</h3>
              <span className="text-sm text-gray-500">Upcoming dues invoices will be generated 1 month before they are due</span>

              <div className="px-4">

                <p className="text-gray-400 font-medium">
                  <span className="text-gray-900"> Invoices </span> for these dues will be due on the
                  <select name="fund[due_day]" className="mt-2 mx-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" value={dueDay} onChange={(e) => setDueDay(e.target.value)}>
                    {dueDayOptions.map((o) => (
                      <option key={o[1]} value={o[1]}>{o[0]}</option>
                    ))}
                  </select>
                  of every month.
                </p>
              </div>
            </div>

            <ToggleAutoReminders autoRemind={autoRemind} setAutoRemind={setAutoRemind} />
            <div className="mx-4 overflow-hidden">
              <Transition
                as={"div"}
                show={autoRemind}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
              >
                {scheduledReminders.map((reminder, index) => {
                  return <ReminderForm key={index} reminder={reminder} index={index} />
                })}
              </Transition>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  )
}

export default InvoiceScheduleForm;
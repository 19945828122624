import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ProductsProvider, useProducts } from "./StateManagement";
import { ProductCard } from "./ProductCard";

const ProductsGrid = ({ newProductPath }) => {
  const { state, toggleDrag } = useProducts();
  const { products, draggable } = state;

  const ToggleBG = !!draggable ? "bg-indigo-600" : "bg-gray-200";
  const ToggleBubbleBG = !!draggable ? "translate-x-5" : "translate-x-0";

  return (
    <>
      <h2 className="mt-6 mb-3 text-2xl text-gray-700 font-light">
        Your Products
      </h2>

      <div className="mt-6 mb-3 flex align-center">
        <span
          role="checkbox"
          tabIndex="0"
          aria-checked="false"
          onClick={toggleDrag}
          className={`${ToggleBG} relative inline-flex flex-shrink-0 h-6 mr-2 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
        >
          <span
            aria-hidden="true"
            className={`${ToggleBubbleBG} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
          ></span>
        </span>
        <span
          onClick={toggleDrag}
          className="text-sm leading-loose text-gray-400"
          title="Toggle to enable reordering by dragging and dropping the cards below"
        >
          Enable drag-and-drop reordering
        </span>
      </div>

      <div className="flex border-b my-4">
        <span className=" pb-4 border-b border-green-400 text-green-400">
          All Products ({products.length})
        </span>
      </div>

      <table className="w-full">
        <thead>
          <tr className=" text-left">
            <th className="pb-4">Product Info</th>
            <th className="pb-4">Price</th>
            <th className="pb-4">Quantity</th>
            <th className="pb-4">Active</th>
            <th className="pb-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {!!products.length &&
            products.map((f, i) => (
              <ProductCard key={f.id} index={i} product={f} />
            ))}
        </tbody>
      </table>
    </>
  );
};

const Products = ({ products, newProductPath }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ProductsProvider products={products} newProductPath={newProductPath}>
        <ProductsGrid newProductPath={newProductPath} />
      </ProductsProvider>
    </DndProvider>
  );
};

export default Products;

import React from "react"
import { useForm } from "./StateManagement"
import ShareThis from "../ShareThis.jsx"

export const ReceiptComponent = () => {
  const { state: { chargeData, user, organization, saveBillingInfo, currentDueLevel, autoPay, force_autopay } } = useForm();

  let showSubscriptionText = autoPay
  showSubscriptionText = (currentDueLevel && currentDueLevel.recurring && force_autopay)

  return <>
    {organization.id == 77 ? <ThankYou /> : ''}
    {showSubscriptionText ? <h3 className="mt-8 mb-4 text-xl font-medium font-body leading-5 text-gray-700">Success! Subscription Created.</h3>
      :
      <h3 className="mt-8 mb-4 text-xl font-medium font-body leading-5 text-gray-700">Success! Transaction complete.</h3>
    }
    <p className="text-xs text-gray-600">Summary</p>
    <div className="container p-3 mb-8 bg-gray-50">
      <p className="text-xs text-gray-600">Payment: ${chargeData.amount}</p>
      <p className="text-xs text-gray-600">Processing Fee: ${chargeData.totalFee}</p>
      <hr className="my-2 border-t border-gray-200" />
      <p className="text-xs text-gray-700"><strong>Total: ${chargeData.total}</strong></p>
    </div>

    {saveBillingInfo ?
      <p className="mb-10 leading-5 text-gray-700 text-md">A receipt and account confirmation email has been emailed to <span className="font-medium font-body">{user.email}</span>.</p>
      :
      <p className="mb-10 leading-5 text-gray-700 text-md">A receipt has been emailed to <span className="font-medium font-body">{user.email}</span>.</p>
    }
  </>
}

const ThankYou = () => {
  return (
    <>
      <div className="flex justify-center">
        <iframe src="https://player.vimeo.com/video/436824941?autoplay=1" width="275" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      </div>
      <div className="mt-5">
        <ShareThis
          alignment="center"
          networks={['facebook', 'messenger', 'twitter', 'email', 'sms']}
        />
        <p className="mr-5 text-lg text-center text-body font-semibold leading-normal text-purple-700">Please consider sharing on social media!</p>
      </div>
    </>
  );
};

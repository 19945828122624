import React, { useState } from 'react';
import { generateId } from "../../utils/genIds";

export default (props) => {
  // const { model, modelName, attribute, formFields, formSelectOptions } = props;
  const { formFields, state, dispatch } = props;

  const CompMap = {
    text: CustomInput,
    checkbox: Checkbox,
    "drop down": CustomDropDown,
  };

  return (
    <div className="grid grid-cols-1 gap-x-4 mt-1 sm:grid-cols-6">
      {formFields.map((ff, i) => {
        const InputComponent = CompMap[ff.input_type] || CustomInput;
        return <InputComponent key={i} index={i} formField={ff} {...props} />;
      })}
    </div>
  );
};

const CustomDropDown = ({ index, attribute, modelName, formField, formSelectOptions, state, dispatch }) => {
  const namePrefix = modelName ? `${modelName}[${attribute}][${index}]` : `${attribute}[${index}]`;
  const id = generateId();

  const handleChange = (e) => {
    const value = e.target.value;
    if (state && dispatch) {
      dispatch({
        type: "UPDATE_FORM_VALUE",
        formFieldId: formField.id,
        value,
      });
    }
  };

  const options = formSelectOptions.filter(
    (o) => o.form_field_id === formField.id
  );

  return (
    <div className="py-2 sm:col-span-2">
      <label className="block text-sm leading-5 font-medium text-gray-700">
        {formField.label}
        {formField.required ? "*" : ""}
      </label>
      <input type="hidden" name={`${namePrefix}[form_field_id]`} value={formField.id} />
      <select
        name={`${namePrefix}[value]`}
        required={formField.required}
        onChange={(e) => {
          handleChange(e)
        }}
        className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5"
      >
        <option value="default" hidden>
          Choose one
        </option>
        {options.map((o) => (
          <option key={o.id} value={o.value}>
            {o.value}
          </option>
        ))}
      </select>
    </div>
  );
};

const Checkbox = ({ formField, modelName, attribute, index, state, dispatch }) => {
  const namePrefix = modelName ? `${modelName}[${attribute}][${index}]` : `${attribute}[${index}]`;
  const handleChange = (e) => {
    const value = e.target.value;
    if (state && dispatch) {
      dispatch({
        type: "UPDATE_FORM_VALUE",
        formFieldId: formField.id,
        value,
      });
    }
  };

  return (
    <>
      <div className="py-2 sm:col-span-2">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {formField.label}
          {formField.required ? "*" : ""}
        </label>
        <input type="hidden" name={`${namePrefix}[form_field_id]`} value={formField.id} />
        <div className="mt-1">
          <input
            type="hidden"
            name={`${namePrefix}[value]`}
            value="false"
          />
          <input
            required={formField.required}
            type="checkbox"
            name={`${namePrefix}[value]`}
            onChange={handleChange}
            // defaultChecked={defaultValue}
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
        </div>
      </div>
    </>
  );
};

const CustomInput = ({ formField, attribute, index, modelName, state, dispatch }) => {
  const namePrefix = modelName ? `${modelName}[${attribute}][${index}]` : `${attribute}[${index}]`;
  const handleChange = (e) => {
    const value = e.target.value;
    if (state && dispatch) {
      dispatch({
        type: "UPDATE_FORM_VALUE",
        formFieldId: formField.id,
        value,
      });
    }
  };

  return (
    <>
      <div className="py-2 sm:col-span-2">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {" "}
          {formField.label}
          {formField.required ? "*" : ""}
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input type="hidden" name={`${namePrefix}[form_field_id]`} value={formField.id} />
          <input
            type={formField.input_type}
            name={`${namePrefix}[value]`}
            placeholder={formField.placeholder}
            required={formField.required}
            className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};


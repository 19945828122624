import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { calcFee } from "../../utils/stripeFee";
import axios from "../../utils/requests";

const StripeContext = createContext(null);

const initialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postalcode: "",
  },
  organization: {},
  fund: {},
  formSetting: {},
  fundFormFields: [],
  feeModel: {},
  chargeData: {},
  amount: null,
  subtotal: null,
  completed: false,
  loading: false,
  errors: {},
  autoPay: false,
  saveBillingInfo: false,
  currentDueLevel: null,
  transaction_id: null,
  paymentMethod: null,
  subscription_id: null,
  preloadAmount: null,
  create_intent_path: null,
  create_subscription_path: null,
  force_autopay: false,
  future_start_date: new Date().toISOString().split('T')[0],
  subscription_future_start: false,
  future_start_date_enabled: false,
};

export const StripeFormProvider = (props) => {
  // added  the form state
  const state = {
    ...initialState,
    organization: props.organization,
    recaptcha_site_key: props.recaptcha_site_key,
    recaptcha_enabled: props.recaptcha_enabled,
    create_intent_path: props.create_intent_path,
    create_subscription_path: props.create_subscription_path,
    fund: props.fund,
    dueLevels: props.due_levels,
    feeModel: props.fee_model,
    fundFormFields: props.fund_form_fields || [],
    paymentMethods: props.payment_methods,
    formSelectOptions: props.form_select_options || [],
    formSetting: props.form_setting || {},
    preloadAmount: props.preload_amount || null,
    force_autopay: props.force_autopay,
    subscription_future_start: props.subscription_future_start,
  };

  if (props.payment_methods?.length > 0) {
    state.paymentMethod = state.paymentMethods[0].id;
  }

  props.current_user ? (state.user = props.current_user) : null;

  if (props.fund.fund_type === "dues") {
    state.amount = state.dueLevels[0].amount;
    state.currentDueLevel = state.dueLevels[0];
    state.autoPay = (state.dueLevels[0].recurring && state.force_autopay) ? true : false;
  } else if (state.preloadAmount) {
    state.amount = Number(Number(state.preloadAmount).toFixed(2))
  }

  // sets initial charge data
  state.chargeData = calcFee(state.amount, state.feeModel, state.autoPay);

  const [store, dispatch] = useReducer(stripeFormReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <StripeContext.Provider value={value} {...props} />;
};

const stripeFormReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_RECAPTCHA":
      return { ...state, recaptcha_response: action.recaptcha_response };
    case "UPDATE_USER":
      return { ...state, user: action.user };
    case "UPDATE_FUND_FORM_FIELDS":
      return { ...state, fundFormFields: action.fundFormFields };
    case "UPDATE_FUTURE_START_DATE":
      return { ...state, future_start_date: action.date };
    case "TOGGLE_FUTURE_START_DATE_ENABLED":
      return { ...state, future_start_date_enabled: !state.future_start_date_enabled };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "TOGGLE_COMPLETED":
      return { ...state, completed: !state.completed };
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "SET_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    case "SET_TRANSACTION_ID":
      return { ...state, transaction_id: action.transaction_id };
    case "SET_SUBSCRIPTION_ID":
      return { ...state, subscription_id: action.subscription_id };
    case "UPDATE_AMOUNT":
      return { ...state, amount: action.value };
    case "UPDATE_AUTOPAY":
      return { ...state, autoPay: action.autoPay };
    case "UPDATE_CURRENT_DUE_LEVEL":
      return { ...state, currentDueLevel: action.dueLevel };
    case "UPDATE_CHARGE_DATA":
      return { ...state, chargeData: action.chargeData };
    case "UPDATE_SAVE_BILLING_INFO":
      return { ...state, saveBillingInfo: action.saveBillingInfo };
    default:
      return state;
  }
};

export const useForm = () => {
  const [state, dispatch] = useContext(StripeContext);

  const updateUserAttribute = useCallback(
    (attribute, value) => {
      const user = { ...state.user };
      user[attribute] = value;

      if (attribute === "password") user["password_confirmation"] = value;

      dispatch({ type: "UPDATE_USER", user });
    },
    [state, dispatch]
  );

  const updateFormValue = useCallback(
    (formField, value) => {
      dispatch({
        type: "UPDATE_FUND_FORM_FIELDS",
        fundFormFields: state.fundFormFields.map((cur) => {
          if (cur.id === formField.id) {
            return { ...cur, value };
          }
          return { ...cur };
        }),
      });
    },
    [state, dispatch]
  );

  const setPaymentMethod = useCallback(
    (method) => {
      dispatch({ type: "SET_PAYMENT_METHOD", paymentMethod: method.id });
    },
    [state, dispatch]
  );

  const updateAmount = (value) => {
    dispatch({ type: "UPDATE_AMOUNT", value: parseFloat(value) });
    dispatch({
      type: "UPDATE_CHARGE_DATA",
      chargeData: calcFee(parseFloat(value), state.feeModel, state.autoPay),
    });
  };

  const updateDueLevel = (id) => {
    let dueLevel = state.dueLevels.find((e) => e.id == id);
    dispatch({ type: "UPDATE_CURRENT_DUE_LEVEL", dueLevel });
    dispatch({ type: "UPDATE_AMOUNT", value: parseFloat(dueLevel.amount) });
    if (dueLevel.recurring && state.force_autopay) {
      dispatch({ type: "UPDATE_AUTOPAY", autoPay: true });
    } else {
      dispatch({ type: "UPDATE_AUTOPAY", autoPay: false });
    }
    dispatch({
      type: "UPDATE_CHARGE_DATA",
      chargeData: calcFee(
        parseFloat(dueLevel.amount),
        state.feeModel,
        (state.force_autopay && dueLevel.recurring) ? true : state.autoPay
      ),
    });
  };

  const setCustomDueLevel = (id) => {
    const dueLevel = {
      id,
      name: "Custom Amount",
      description: null,
      amount: 0,
      amount_cents: 0,
      amount_currency: "USD",
      interval: null,
      recurring: false,
    };

    // Set temp custom duelevel
    dispatch({ type: "UPDATE_CURRENT_DUE_LEVEL", dueLevel });
    dispatch({ type: "UPDATE_AMOUNT", value: parseFloat(dueLevel.amount) });
    dispatch({
      type: "UPDATE_CHARGE_DATA",
      chargeData: calcFee(parseFloat(dueLevel.amount), state.feeModel),
    });

    // Disable ecurring payment as this will be a single charge
    dispatch({ type: "UPDATE_AUTOPAY", autoPay: false });
  };

  const toggleSaveBillingInfo = () => {
    dispatch({
      type: "UPDATE_SAVE_BILLING_INFO",
      saveBillingInfo: !state.saveBillingInfo,
    });
  };

  const updateAutoPay = (autoPay) => {
    dispatch({ type: "UPDATE_AUTOPAY", autoPay });
    dispatch({
      type: "UPDATE_CHARGE_DATA",
      chargeData: calcFee(
        parseFloat(state.currentDueLevel.amount),
        state.feeModel,
        autoPay
      ),
    });

    if (autoPay) {
      dispatch({ type: "UPDATE_SAVE_BILLING_INFO", saveBillingInfo: autoPay });
    }
  };

  const updateFutureStartDate = (date) => {
    dispatch({ type: "UPDATE_FUTURE_START_DATE", date: date });
  }

  return {
    state,
    stripe: useStripe(),
    elements: useElements(),
    toggleSaveBillingInfo,
    updateFormValue,
    updateUserAttribute,
    updateAmount,
    updateDueLevel,
    updateFutureStartDate,
    setCustomDueLevel,
    updateAutoPay,
    setPaymentMethod,
    dispatch,
  };
};


export const handleFormSubmit = async ({ state, dispatch, stripe, elements }) => {
  const { currentDueLevel, autoPay, force_autopay } = state;
  if (currentDueLevel.recurring && force_autopay) {
    handleSubscriptionCreate({ state, dispatch, stripe, elements });
  } else if (currentDueLevel.recurring && autoPay) {
    handleSubscriptionCreate({ state, dispatch, stripe, elements });
  } else {
    singleChargeSubmit({ state, dispatch, stripe, elements });
  }
}


// this is the function that handles form submission of single charges
export const singleChargeSubmit = async ({
  state,
  dispatch,
  stripe,
  elements,
}) => {
  const {
    user,
    chargeData,
    organization,
    fund,
    currentDueLevel,
    transaction_id,
    saveBillingInfo,
    paymentMethod,
    recaptcha_response,
    recaptcha_enabled,
    create_intent_path,
  } = state;

  dispatch({ type: "TOGGLE_LOADING" });

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    dispatch({ type: "TOGGLE_LOADING" });
    return;
  }

  if (!!recaptcha_enabled && !!recaptcha_response === false) {
    dispatch({ type: "TOGGLE_LOADING" });
    dispatch({ type: "SET_ERRORS", errors: { base: ["Invalid Request"] } });
    return;
  }

  let form_values_attributes = [];
  state.fundFormFields.forEach((v) => {
    if (v.value) {
      form_values_attributes.push({ form_field_id: v.id, value: v.value });
    }
  });

  let params = {
    user_attributes: user,
    amount: chargeData.amount,
    receipt_email: user.email,
    email: user.email,
    phone: user.phone,
    first_name: user.first_name,
    last_name: user.last_name,
    organization_id: organization.id,
    fund_id: fund.id,
    fund_accounting_id: fund.accounting_id,
    membership_id: user.membership_id,
    form_values_attributes,
    transaction_id,
    save_billing_info: saveBillingInfo,
    payment_method: paymentMethod,
    recaptcha_response: recaptcha_response,
  };

  if (currentDueLevel !== null && currentDueLevel.id !== "custom_amount") {
    params.due_level_id = currentDueLevel.id;
  }



  // Create payment method
  // TODO PULL this into a new method,
  // only create a payment method if one is not set in the stage.
  // if a payment method is already in the state just use it. and
  // skip this method
  if (!paymentMethod) {
    const paymentMethodResponse = await createPaymentMethod({
      stripe,
      elements,
      organization,
      fund,
      user,
    });

    // return early and show errors if payment method doesnt create on stripe
    if (paymentMethodResponse.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [paymentMethodResponse.error.message] },
      });
      return;
    }

    // set payment method id on the state so we dont do it again.
    dispatch({
      type: "SET_PAYMENT_METHOD",
      paymentMethod: paymentMethodResponse.paymentMethod.id,
    });
    params.payment_method = paymentMethodResponse.paymentMethod.id;
  }

  try {
    const response = await axios.post(create_intent_path, {
      ...params,
    });

    dispatch({
      type: "SET_TRANSACTION_ID",
      transaction_id: response.data.transaction.id,
    });

    const result = await stripe.confirmCardPayment(
      response.data.stripe_intent.client_secret,
      { payment_method: params.payment_method }
    );

    if (result.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [result.error.message] },
      });
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // what do we do on the success of a card transaction
        dispatch({ type: "TOGGLE_COMPLETED" });
        dispatch({ type: "TOGGLE_LOADING" });
      }
    }
  } catch (error) {
    if (error.response.data.recaptcha_failed) {
      dispatch({ type: "SET_ERRORS", errors: { base: ["Invalid Request"] } });
      dispatch({ type: "TOGGLE_LOADING" });
    } else {

      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({ type: "SET_ERRORS", errors: error.response.data.errors });

      if (error.response.data.transaction_id) {
        dispatch({
          type: "SET_TRANSACTION_ID",
          transaction_id: error.response.data.transaction_id,
        });
      }
    }
  }
};

export const handleSubscriptionCreate = async ({
  state,
  dispatch,
  stripe,
  elements,
}) => {
  const {
    paymentMethod,
    organization,
    chargeData,
    fund,
    currentDueLevel,
    user,
    saveBillingInfo,
    transaction_id,
    subscription_id,
    recaptcha_response,
    recaptcha_enabled,
    create_subscription_path,
    future_start_date,
    future_start_date_enabled,
  } = state;

  dispatch({ type: "TOGGLE_LOADING" });
  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    dispatch({ type: "TOGGLE_LOADING" });
    return;
  }

  if (!!recaptcha_enabled && !!recaptcha_response === false) {
    dispatch({ type: "TOGGLE_LOADING" });
    dispatch({ type: "SET_ERRORS", errors: { base: ["Invalid Request"] } });
    return;
  }


  let form_values_attributes = [];
  state.fundFormFields.forEach((v) => {
    if (v.value) {
      form_values_attributes.push({ form_field_id: v.id, value: v.value });
    }
  });

  let params = {
    user_attributes: user,
    amount: chargeData.amount,
    organization_id: organization.id,
    fund_id: fund.id,
    due_level_id: currentDueLevel.id,
    payment_method: paymentMethod,
    fund_accounting_id: fund.accounting_id,
    membership_id: user.membership_id,
    receipt_email: user.email,
    email: user.email,
    phone: user.phone,
    first_name: user.first_name,
    last_name: user.last_name,
    save_billing_info: saveBillingInfo,
    form_values_attributes,
    transaction_id,
    subscription_id,
    recaptcha_response,
  };
  if (future_start_date_enabled) {
    params.future_start_date = new Date(future_start_date).getTime() / 1000
    params.future_start_date_enabled = future_start_date_enabled
  }

  if (!paymentMethod) {
    const result = await createPaymentMethod({
      stripe,
      elements,
      organization,
      fund,
      user,
    });

    if (result.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [result.error.message] },
      });
      return;
    }
    dispatch({
      type: "SET_PAYMENT_METHOD",
      paymentMethod: result.paymentMethod.id,
    });
    params.payment_method = result.paymentMethod.id;
  }

  try {
    const response = await axios.post(create_subscription_path, params);

    dispatch({ type: "TOGGLE_COMPLETED" });
    dispatch({ type: "TOGGLE_LOADING" });
    // dispatch({ type: 'SET_SUBSCRIPTION_ID', subscription_id: response.data.subscription_id })


  } catch (error) {
    if (error.response.data.recaptcha_failed) {
      dispatch({ type: "SET_ERRORS", errors: { base: ["Invalid Request"] } });
      dispatch({ type: "TOGGLE_LOADING" });
    } else {

      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({ type: "SET_ERRORS", errors: error.response.data.errors });
      dispatch({
        type: "SET_SUBSCRIPTION_ID",
        subscription_id: error.response.data.subscription_id,
      });
    }
  }
};

const createPaymentMethod = async ({
  stripe,
  elements,
  organization,
  fund,
  user,
}) => {
  return await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    metadata: {
      organization_id: organization.id,
      fund_id: fund.id,
    },
    billing_details: {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone: user.phone && user.phone.length > 0 ? user.phone : null,
      address: {
        line1: user.line1 && user.line1.length > 0 ? user.line1 : null,
        line2: user.line2 && user.line2.length > 0 ? user.line2 : null,
        city: user.city && user.city.length > 0 ? user.city : null,
        state: user.region && user.region.length > 0 ? user.region : null,
        country: user.country && user.country.length > 0 ? user.country : null,
        postal_code:
          user.postalcode && user.postalcode.length > 0
            ? user.postalcode
            : null,
      },
    },
  });
};

import React, { useRef } from "react";
import { useProducts } from "./StateManagement";
import { ItemTypes } from "./ItemTypes";

import { ProductHeading, ProductStatus } from "./CardElements";

export const ProductCard = ({ product, index }) => {
  const {
    state,
    useDrag,
    useDrop,
    moveProduct,
    updatePositions,
  } = useProducts();
  const { draggable } = state;

  const ref = useRef(null);

  const [{ opacity }, drag] = useDrag({
    item: { id: product.id, index },
    type: ItemTypes.PRODUCT,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    canDrag: () => draggable,
  });

  const [, drop] = useDrop({
    accept: ItemTypes.PRODUCT,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 100) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 100) return;

      // Time to actually perform the action
      moveProduct(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item) {
      updatePositions(item);
    },
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      style={{ opacity }}
      className={`shadow-md rounded odd:bg-white even:bg-gray-50 hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out`}
    >
      <td className="p-5 pb-3">
        <a href={product.product_path}>
          <ProductHeading
            imageUrl={product.imageUrl}
            name={product.name}
            productType={product.product_type}
          />
        </a>
      </td>
      <td className="">{product.price_dollars}</td>
      <td className="">{product.quantity}</td>
      <td className="">
        <ProductStatus status={product.status} path={product.product_path} />
      </td>
      <td className="">
        <a
          href={product.product_path}
          className="flex items-center font-semibold text-indigo-500 hover:text-indigo-200 hover:underline transition duration-150 ease-in-out"
        >
          View
        </a>
        <a
          href={product.product_path + "/edit"}
          className="flex items-center font-semibold text-indigo-500 hover:text-indigo-200 hover:underline transition duration-150 ease-in-out"
        >
          Edit
        </a>
      </td>

      {/* <td className="">1203</td> */}
    </tr>
  );
};

import React from 'react';
import {
  useForm,
  handleFormSubmit,
} from './StateManagement';
import { DuesAmountInput, AmountInput, Input } from './FormInputs';

export const DuesForm = ({ children }) => {
  const { state, dispatch, stripe, elements } = useForm();
  const { organization, formSetting } = state;

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleFormSubmit({ state, dispatch, stripe, elements });
  };

  const memberIdLabel = organization.membership_label
    ? organization.membership_label
    : 'Membership ID';

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <DuesAmountInput />
        {state.currentDueLevel.id === 'custom_amount' && <AmountInput />}
        {formSetting.include_membership_id_field && (
          <Input
            type="text"
            label={memberIdLabel}
            name="membership_id"
            isRequired={formSetting.require_membership_id_field}
          />
        )}
        {children}
      </fieldset>
    </form>
  );
};

export function calcStoreFee(amountCents, feeModel) {
    const amountWithFees = storeAmountIncludingFeeCents(amountCents, feeModel);
    return amountWithFees - amountCents;    
}

function storeUnionlyFeeCents(amountCents, feeModel) {
    const amount = amountCents * (parseFloat(feeModel.ecommerce_percent) / 100);
    return Math.round(amount);
}

function storeAmountIncludingFeeCents(amountCents, feeModel) {
    const amount = (amountCents + storeUnionlyFeeCents(amountCents, feeModel)) / 100;
    return Math.round(((amount + parseFloat(feeModel.fixed)) / (1 - (parseFloat(feeModel.percent) / 100))) * 100);
}
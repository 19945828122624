import React from 'react';
import { useForm, singleChargeSubmit, loggedInSubmit } from './StateManagement';
import { AmountInput, Input } from './FormInputs';

export const SingleChargeForm = ({ children }) => {
  const { state, dispatch, stripe, elements } = useForm();
  const { organization, fund, formSetting } = state;

  const handleSubmit = (event) => {
    event.preventDefault();
    singleChargeSubmit({ state, dispatch, stripe, elements });
  };

  const memberIdLabel = organization.membership_label
    ? organization.membership_label
    : 'Membership ID';

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <AmountInput />
        {fund.fund_type === 'misc_dues' &&
          formSetting.include_membership_id_field ? (
          <Input
            type="text"
            label={memberIdLabel}
            name="membership_id"
            isRequired={formSetting.require_membership_id_field}
          />
        ) : (
          ''
        )}
        {children}
      </fieldset>
    </form>
  );
};

import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { updateProductPositions } from '../../utils/requests';
const ProductsContext = createContext(null);

const initialState = {
  products: [],
  draggable: false,
};

export const ProductsProvider = (props) => {
  const { products = [] } = props;
  const state = {
    ...initialState,
    products,
  };

  const [store, dispatch] = useReducer(productsReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <ProductsContext.Provider value={value} {...props} />;
};

const productsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FUNDS':
      return { ...state, products: action.products };
    case 'TOGGLE_DRAG':
      return { ...state, draggable: !state.draggable };
    default:
      return state;
  }
};

export const useProducts = () => {
  const [state, dispatch] = useContext(ProductsContext);

  const moveProduct = useCallback(
    (dragIndex, hoverIndex) => {
      const dragProduct = state.products[dragIndex];
      // const hoverProduct = stat.products[hoverIndex]
      const newProducts = [...state.products];

      // first remove the item at dragIndex (the product that is being dragged)
      // then insert the dragProduct at the product that is being hovered over
      newProducts.splice(dragIndex, 1);
      newProducts.splice(hoverIndex, 0, dragProduct);

      // update position for the product we dragged
      const sortedProducts = newProducts.map((f, i) => {
        if (f.id != dragProduct.id) return f;

        const position =
          dragIndex > hoverIndex
            ? f.position - (dragIndex - hoverIndex)
            : f.position + (hoverIndex - dragIndex);

        return { ...f, position };
      });

      dispatch({ type: 'UPDATE_FUNDS', products: sortedProducts });
    },
    [state.products]
  );

  const updatePositions = async (productItem) => {
    const product = state.products.find((f) => f.id == productItem.id);

    try {
      await updateProductPositions({
        product: { id: product.id, position: product.position },
      });
    } catch (error) {
    }
  };

  const toggleDrag = () => {
    dispatch({ type: 'TOGGLE_DRAG' });
  };

  return {
    state,
    dispatch,
    useDrag,
    useDrop,
    moveProduct,
    updatePositions,
    toggleDrag,
  };
};

import React, { useLayoutEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

function createCheckoutSession(planId) {
  return axios.get('/stripe_checkout', {
    params: {
      planId: planId,
    },
  });
}

const SignupCheckoutButton = (props) => {
  const { planId, publishableKey } = props;

  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [stripe, setStripe] = useState(null);

  useLayoutEffect(() => {
    const promise = loadStripe(publishableKey);
    setStripe(promise);
    setSdkLoaded(true);
  }, []);

  const handleClick = async () => {
    let resp = await createCheckoutSession(planId);
    let s = await stripe;
    s.redirectToCheckout({
      sessionId: resp.data.sessionId,
    });
  };

  return (
    <>
      {sdkLoaded ? (
        <div className="mt-6 rounded-md shadow">
          <button
            onClick={handleClick}
            className=" flex w-full items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-yellow-800 hover:bg-yellow-900 focus:outline-none focus:ring transition duration-150 ease-in-out"
          >
            Get started
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SignupCheckoutButton;

import React, { useState } from 'react';
import { StripeSDKLoader } from "../utils/StripeSdkLoader";
import axios from "../utils/requests";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"

const CARD_OPTIONS = {
  hidePostalCode: false,
  iconStyle: 'solid',
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Rubik", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6B6B6B',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

export const NewPaymentMethodSection = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorClass, setErrorClass] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    // could show the error here if we wanted to
    const { error } = event;

    if (!!error) {
      setErrorClass('border-red-600');
    } else {
      setErrorClass('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const resp = await submitPaymentMethod({ state: props, stripe, elements });
    // return early and show errors if payment method doesnt create on stripe
    if (resp.error) {
      setLoading(false);
      setErrors({ base: [resp.error.message] })
      return;
    }

    try {
      await axios.post(
        props.create_card_path,
        {
          payment_method: resp.paymentMethod
        }
      );
    } catch (error) {
      setLoading(false)
      setErrors({ base: error.response.data.error });
      return
    }
    setLoading(false)
    // Redirect to the members page
    window.location = props.success_redirect_path
  };

  const btnLoading = !stripe || loading;
  let buttonStyles = "w-full text-center py-3 border border-transparent leading-4 rounded-md text-white ease-in-out transition focus:outline-none "
  if (btnLoading) {
    buttonStyles = buttonStyles.concat("bg-indigo-200")
  } else {
    buttonStyles = buttonStyles.concat("bg-indigo-600 hover:bg-indigo-800 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 duration-150")
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="my-6">
        <div className="grid sm:grid-cols-4 gap-2">

          <div className={`p-3 border rounded-md sm:col-span-3 ${errorClass}`}>
            <CardElement options={CARD_OPTIONS} onChange={handleChange} />
          </div>
          <button
            type="submit"
            disabled={btnLoading}
            className={buttonStyles}
          >
            {btnLoading ? "Loading…" : "Create Card"}
          </button>
        </div>
        {errors && errors.base && <div className="text-red-600 text-center">{errors.base}</div>}
      </form>
    </>
  )

}

const submitPaymentMethod = async ({ state, stripe, elements }) => {
  const { organization, fund, member } = state;
  const user = member.user
  return await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    metadata: {
      organization_id: organization.id,
      fund_id: fund.id,
    },
    billing_details: {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone: user.phone && user.phone.length > 0 ? user.phone : null,
      address: {
        line1: user.line1 && user.line1.length > 0 ? user.line1 : null,
        line2: user.line2 && user.line2.length > 0 ? user.line2 : null,
        city: user.city && user.city.length > 0 ? user.city : null,
        state: user.region && user.region.length > 0 ? user.region : null,
        country: user.country && user.country.length > 0 ? user.country : null,
        postal_code:
          user.postalcode && user.postalcode.length > 0
            ? user.postalcode
            : null,
      },
    },
  });
};

export default function CreatePaymentMethod(props) {
  return (
    <>
      <svg onClick={() => { window.history.back() }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
      </svg>
      <h5 className="text-2xl text-center text-gray-700 ">
        Create Card
      </h5>
      <StripeSDKLoader stripe_publishable_key={props.stripe_publishable_key}>
        <NewPaymentMethodSection {...props} />
      </StripeSDKLoader>
    </>
  )
}
import React, { useState } from 'react';

const FlashNotice = (props) => {
  const { stringToCopy, buttonText, label } = props;

  const handleClick = () => {
    navigator.clipboard.writeText(stringToCopy);
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex rounded-md shadow-sm justify-end">
          <div className="relative">
            <input
              style={{}}
              className="form-input block w-full rounded-none rounded-l-md pl-2 bg-white transition ease-in-out duration-150 sm:text-sm sm:leading-5 truncate"
              value={stringToCopy}
              disabled
            />
          </div>
          <button
            onClick={() => handleClick()}
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-800 bg-yellow-800  hover:bg-yellow-300 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 transition ease-in-out duration-150"
          >
            {buttonText ? buttonText : 'copy'}
          </button>
        </div>
        {label ? (
          <label className="block text-sm text-right leading-5 text-gray-600 font-light">
            {label}
          </label>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default FlashNotice;

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { FundsProvider, useFunds } from './StateManagement';
import { FundCard } from './FundCard';

const FundsGrid = ({ newFundPath }) => {
  const { state, toggleDrag } = useFunds();
  const { funds, draggable } = state;

  const ToggleBG = !!draggable ? 'bg-indigo-600' : 'bg-gray-200';
  const ToggleBubbleBG = !!draggable ? 'translate-x-5' : 'translate-x-0';

  return (
    <>
      <h2 className="mt-6 mb-3 text-2xl text-gray-700 font-light">
        Your Fundraiser Pages
      </h2>

      <div className="mt-6 mb-3 flex align-center">
        <span
          role="checkbox"
          tabIndex="0"
          aria-checked="false"
          onClick={toggleDrag}
          className={`${ToggleBG} relative inline-flex flex-shrink-0 h-6 mr-2 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring`}
        >
          <span
            aria-hidden="true"
            className={`${ToggleBubbleBG} inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
          ></span>
        </span>
        <span
          onClick={toggleDrag}
          className="text-sm leading-loose text-gray-400"
          title="Toggle to enable reordering by dragging and dropping the cards below"
        >
          Enable drag-and-drop reordering
        </span>
      </div>

      <div className="md:grid grid-cols-1 lg:grid-cols-3 lg:gap-6">
        {!!funds.length &&
          funds.map((f, i) => <FundCard key={f.id} index={i} fund={f} />)}
        <a
          href={newFundPath}
          className="flex items-center justify-center mt-4 lg:mt-0  p-5 shadow-md rounded  border-indigo-500 border-2 border-dashed bg-white hover:shadow-lg transition duration-150 ease-in-out"
        >
          <span
            href={newFundPath}
            className="text-md leading-5 font-medium rounded-md text-indigo-600 "
          >
            <svg
              className="h-6 w-6 inline mr-1"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"></path>
            </svg>
            Add Fundraiser Page
          </span>
        </a>
      </div>
    </>
  );
};

const Funds = ({ funds, newFundPath }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <FundsProvider funds={funds} newFundPath={newFundPath}>
        <FundsGrid newFundPath={newFundPath} />
      </FundsProvider>
    </DndProvider>
  );
};

export default Funds;

import React, { useState, useContext, createContext, useReducer } from "react";
import { generateId } from "../utils/genIds";

const newDueLevel = {
  id: null,
  objectId: null,
  name: "",
  description: "",
  amount: "",
  destroy: false,
  status: "active",
};

const initialState = {
  fund: {},
  dueLevels: [],
};

const dueLevelsReducer = (state, action) => {
  switch (action.type) {
    case "ADD_DUE_LEVEL":
      return { ...state, dueLevels: [...state.dueLevels, action.dueLevel] };
    case "SET_DUE_LEVELS":
      return { ...state, dueLevels: action.dueLevels };
    default:
      return state;
  }
};

const DueLevelsContext = createContext(null);

const useDueLevels = () => {
  const [state, dispatch] = useContext(DueLevelsContext);

  const addDueLevel = () => {
    dispatch({
      type: "ADD_DUE_LEVEL",
      dueLevel: { ...newDueLevel, objectId: generateId() },
    });
  };

  const removeDueLevel = (dueLevel) => {
    // const dueLevels = state.dueLevels
    // remove a due level if it does exist
    // toggle destroy a due level if it does exist
    if (dueLevel.id === null) {
      const newduelevels = state.dueLevels.filter(
        (dl) => dl.objectId != dueLevel.objectId
      );
      dispatch({ type: "SET_DUE_LEVELS", dueLevels: newduelevels });
    } else {
      const newduelevels = state.dueLevels.map((dl) => {
        if (dl.objectId === dueLevel.objectId) {
          return { ...dl, destroy: true };
        } else {
          return { ...dl };
        }
      });
      dispatch({ type: "SET_DUE_LEVELS", dueLevels: newduelevels });
    }
  };

  return { state, dispatch, addDueLevel, removeDueLevel };
};

const DueLevelsProvider = (props) => {
  // added  the form state

  const [state, dispatch] = useReducer(dueLevelsReducer, {
    ...initialState,
    fund: props.fund,
    dueLevels: props.dueLevels || [],
    dueLevelStatuses: props.dueLevelStatuses,
    intervalOptions: props.intervalOptions,
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <DueLevelsContext.Provider value={value} {...props} />;
};

const DueLevelFields = (props) => {
  const { removeDueLevel, state } = useDueLevels();
  const { dueLevelStatuses, intervalOptions } = state;
  const { dueLevel, index } = props;
  const [recurring, setRecurring] = useState(dueLevel.recurring);
  const namePrefix = `fund[due_levels_attributes][${index}]`;

  const handleRemoveClick = (e) => {
    e.preventDefault();
    removeDueLevel(dueLevel);
  };

  const handleSetRecurring = (e) => {
    const { checked } = e.target;

    setRecurring(checked);
  };

  return (
    <>
      {dueLevel.destroy ? (
        <>
          <input type="hidden" name={`${namePrefix}[_destroy]`} value="1" />
          <input type="hidden" name={`${namePrefix}[id]`} value={dueLevel.id} />
        </>
      ) : (
        <>
          {dueLevel.id ? (
            <input
              type="hidden"
              name={`${namePrefix}[id]`}
              value={dueLevel.id}
            />
          ) : (
            ""
          )}
          <div className="shadow sm:rounded-md sm:overflow-hidden my-5">
            <div className="grid grid-cols-8 gap-4 p-2 sm:p-3 bg-white">
              <div className="col-span-8 md:col-span-6">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Dues Level Name
                </label>
                <input
                  name={`${namePrefix}[name]`}
                  defaultValue={dueLevel.name}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: Monthly dues"
                />
              </div>

              <div className="col-span-8 md:col-span-2">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Amount
                </label>
                <input
                  name={`${namePrefix}[amount]`}
                  defaultValue={dueLevel.amount}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                  placeholder="Example: $70.00"
                />
              </div>

              <div className="col-span-8">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Status
                </label>
                <select
                  name={`${namePrefix}[status]`}
                  defaultValue={dueLevel.status}
                  className="form-select mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                >
                  {dueLevelStatuses.map((dl) => (
                    <option key={dl} value={dl}>
                      {dl}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-8 grid grid-cols-8 gap-4">
                <p className="col-span-8 text-sm font-small leading-5 text-gray-500">
                  Note: The recurring schedule cannot be changed once saved.
                </p>
                <div className="col-span-6 flex items-center">
                  <input
                    type="hidden"
                    name={`${namePrefix}[recurring]`}
                    value="false"
                  />
                  <input
                    id={`${dueLevel.objectId}Recurring`}
                    type="checkbox"
                    name={`${namePrefix}[recurring]`}
                    checked={recurring}
                    onChange={handleSetRecurring}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor={`${dueLevel.objectId}Recurring`}
                    className="mx-2 block text-sm leading-5 text-gray-900"
                  >
                    Recurring?
                  </label>

                  {recurring && (
                    <select
                      name={`${namePrefix}[interval]`}
                      defaultValue={dueLevel.interval}
                      className="form-select mt-1 form-input w-full sm:w-36 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                    >
                      {Object.keys(intervalOptions).map((key, i) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                <button
                  onClick={handleRemoveClick}
                  className="col-span-8 sm:col-span-2 px-2 py-3 disabled:bg-gray-200 disabled:text-red-600 disabled:cursor-not-allowed bg-gray-50 hover:bg-red-600 text-red-600 hover:text-white transition duration-150 border border-gray-200 text-xs rounded-md"
                >
                  - Remove Due Level
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const DueLevelsContainer = () => {
  const { state } = useDueLevels();
  return (
    <>
      {state.dueLevels.map((dueLevel, index) => {
        return (
          <DueLevelFields
            key={dueLevel.objectId}
            dueLevel={dueLevel}
            index={index}
          />
        );
      })}
    </>
  );
};

const AddDueLevel = () => {
  const { addDueLevel } = useDueLevels();

  const handleClick = (e) => {
    e.preventDefault();
    addDueLevel();
  };

  return (
    <>
      <span className="mt-3 inline-flex rounded-md shadow-sm">
        <button
          onClick={handleClick}
          className="relative inline-flex items-center py-2 px-4 bg-white hover:bg-indigo-500 border border-indigo-500 rounded-md text-sm leading-5 font-medium text-indigo-700 hover:text-white focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        >
          + Add Level
        </button>
      </span>
    </>
  );
};

const NestedDueLevelsFields = ({
  fund,
  dueLevels,
  dueLevelStatuses,
  intervalOptions,
}) => {
  return (
    <>
      <DueLevelsProvider
        fund={fund}
        dueLevels={dueLevels}
        dueLevelStatuses={dueLevelStatuses}
        intervalOptions={intervalOptions}
      >
        <div className="mt-8 pt-8 border-t flex justify-between">
          <div className="col-span-4 md:col-span-3">
            <h3 className="font-display text-xl font-medium leading-6 text-indigo-800">
              Dues Levels
            </h3>
            <p className="mt-1 mb-2 text-sm leading-5 text-gray-500">
              Make sure to update at the bottom once you've added, removed, or
              edited your dues.
            </p>
          </div>
          <div className="col-span-2 md:col-span-1 flex justify-start md:justify-end">
            <AddDueLevel />
          </div>
        </div>

        <div className="flex items-center">
          <input type="hidden" name="fund[allow_custom_amount]" value="false" />
          <input
            id={`fund_allow_custom_amount`}
            type="checkbox"
            name={`fund[allow_custom_amount]`}
            defaultChecked={fund.allow_custom_amount}
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="fund_allow_custom_amount"
            className="ml-2 block text-sm leading-5 text-gray-900"
          >
            Allow members to input a custom amount
          </label>
        </div>

        <DueLevelsContainer />
      </DueLevelsProvider>
    </>
  );
};
export default NestedDueLevelsFields;

import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { calcFee } from "../../utils/stripeFee";
import axios from "../../utils/requests";

const StripeContext = createContext(null);

const initialState = {
  member: {},
  fund: {},
  organization: {},
  invoices: [],
  feeModel: {},
  chargeData: {},
  subtotal: null,
  paymentMethod: null,
  loading: false,
  offlinePayment: false,
  notes: "",
  autoPay: false,
};

export const InvoicePaymentFormProvider = (props) => {
  // added  the form state
  const state = {
    ...initialState,
    fund: props.fund,
    member: props.member,
    organization: props.organization,
    feeModel: props.fee_model,
    paymentMethods: props.payment_methods,
    create_card_payment_path: props.create_card_payment_path,
    create_card_path: props.create_card_path,
    success_redirect_path: props.success_redirect_path,
    offline_payments_enabled: props.offline_payments_enabled,
    invoices: props.invoices.map((invoice) => { return { ...invoice, checked: true } }),
  };

  const checkedInvoices = state.invoices.filter((invoice) => {
    return invoice.checked === true;
  });

  state.amount = Number(checkedInvoices.reduce((a, b) => { return a + b.amount_due_cents }, 0) / 100);
  // state.amount = Number(amount / 100)

  // sets initial charge data
  state.chargeData = calcFee(state.amount, state.feeModel);

  const [store, dispatch] = useReducer(stripeFormReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <StripeContext.Provider value={value} {...props} />;
};

const stripeFormReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, user: action.user };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "TOGGLE_COMPLETED":
      return { ...state, completed: !state.completed };
    case "SET_OFFLINE_PAYMENT":
      return { ...state, offlinePayment: action.offlinePayment };
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "SET_NOTES":
      return { ...state, notes: action.notes };
    case "SET_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    case "SET_TRANSACTION_ID":
      return { ...state, transaction_id: action.transaction_id };
    case "SET_INVOICES":
      return { ...state, invoices: action.invoices };
    case "SET_SUBSCRIPTION_ID":
      return { ...state, subscription_id: action.subscription_id };
    case "UPDATE_AMOUNT":
      return { ...state, amount: action.value };
    case "UPDATE_AUTOPAY":
      return { ...state, autoPay: action.autoPay };
    case "UPDATE_CHARGE_DATA":
      return { ...state, chargeData: action.chargeData };
    default:
      return state;
  }
};

export const useForm = () => {
  const [state, dispatch] = useContext(StripeContext);

  const setPaymentMethod = useCallback(
    (method) => {
      dispatch({ type: "SET_PAYMENT_METHOD", paymentMethod: method });
    },
    [state, dispatch]
  );

  const chargeData = useCallback(
    () => {
      const checkedInvoices = state.invoices.filter((invoice) => {
        return invoice.checked === true;
      });
      const amount = Number(checkedInvoices.reduce((a, b) => { return a + b.amount_due_cents }, 0) / 100);

      return calcFee(amount, state.feeModel, state.autoPay, state.offlinePayment);
    },
    [state, dispatch]
  );


  const setInvoices = useCallback(
    (invoiceId) => {
      const invoices = state.invoices.map((invoice) => {
        if (invoice.id === invoiceId) {
          return { ...invoice, checked: !invoice.checked };
        } else {
          return invoice;
        }
      });
      dispatch({ type: "SET_INVOICES", invoices });
      dispatch({ type: "SET_AMOUNT", invoices });
    },
    [state, dispatch]
  );

  const updateAutoPay = (autoPay) => {
    dispatch({ type: "UPDATE_AUTOPAY", autoPay });
    dispatch({
      type: "UPDATE_CHARGE_DATA",
      chargeData: calcFee(
        parseFloat(state.currentDueLevel.amount),
        state.feeModel,
        autoPay
      ),
    });

    if (autoPay) {
      dispatch({ type: "UPDATE_SAVE_BILLING_INFO", saveBillingInfo: autoPay });
    }
  };

  return {
    state,
    stripe: useStripe(),
    elements: useElements(),
    updateAutoPay,
    setPaymentMethod,
    chargeData,
    setInvoices,
    dispatch,
  };
};

// this is the function that handles form submission of single charges
export const singleChargeSubmit = async ({
  state,
  dispatch,
  stripe,
  chargeData,
  elements,
}) => {
  const {
    invoices,
    offlinePayment,
    notes,
    paymentMethod,
    create_card_payment_path
  } = state;

  dispatch({ type: "TOGGLE_LOADING" });

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    dispatch({ type: "TOGGLE_LOADING" });
    return;
  }

  const invoice_ids = invoices.filter((invoice) => {
    if (invoice.checked === true) {
      return invoice
    }
  }).map((invoice) => invoice.id);

  let params = {
    invoice_ids,
    amount: chargeData().amount,
    offline_payment: offlinePayment,
    notes: notes,
    payment_method: paymentMethod,
  };

  try {
    const response = await axios.post(create_card_payment_path, {
      ...params,
    });
    dispatch({ type: "TOGGLE_LOADING" });
    return { success: true }
  } catch (error) {

    dispatch({ type: "TOGGLE_LOADING" });
    dispatch({ type: "SET_ERRORS", errors: error.response.data.errors });
    return { error: error }
  }
};

export const createPaymentMethod = async ({
  state,
  stripe,
  elements,
}) => {
  const { organization, fund, member } = state;
  const user = member.user
  return await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    metadata: {
      organization_id: organization.id,
      fund_id: fund.id,
    },
    billing_details: {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone: user.phone && user.phone.length > 0 ? user.phone : null,
      address: {
        line1: user.line1 && user.line1.length > 0 ? user.line1 : null,
        line2: user.line2 && user.line2.length > 0 ? user.line2 : null,
        city: user.city && user.city.length > 0 ? user.city : null,
        state: user.region && user.region.length > 0 ? user.region : null,
        country: user.country && user.country.length > 0 ? user.country : null,
        postal_code:
          user.postalcode && user.postalcode.length > 0
            ? user.postalcode
            : null,
      },
    },
  });
};

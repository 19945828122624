import React, { useState, useRef, useEffect } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import axios from "../../utils/requests";

export default function SearchMembers({ setFilteredMembers, fund }) {

  const [search, setSearch] = useState('')
  const searchRef = useRef(null);

  useEffect(() => {
    const getData = setTimeout(() => {
      axios
        .get(`/dashboard/invoicing/${fund.id}/members.json?search_value=${search}`)
        .then((response) => {
          setFilteredMembers(response.data)
        });
    }, 500)

    return () => clearTimeout(getData)
  }, [search]);

  const handleSubmit = (e) => {
    e.preventDefault()
  }


  return (
    <form onSubmit={handleSubmit} className="mt-6 flex space-x-4" action="#">
      <div className="min-w-0 flex-1">
        <label htmlFor="MemberSearch" className="sr-only">
          Search
        </label>
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="search"
            name="search"
            id="MemberSearch"
            ref={searchRef}
            value={search}
            onChange={e => setSearch(e.target.value)}
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="Search"
          />
        </div>
      </div>
    </form>
  )

}
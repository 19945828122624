
import React, { useState } from "react";



const PaymentMethodManager = ({ payment_methods, customer, organization }) => {

  const defaultPaymentMethod = payment_methods.find((pm) => {
    return pm.id == customer.invoice_settings.default_payment_method
  })

  const otherPaymentMethods = payment_methods.filter((pm) => {
    return pm.id != customer.invoice_settings.default_payment_method
  })

  return (
    <>
      <ul role="list" className="">
        {defaultPaymentMethod && <>

          <p className="mt-5 text-lg leading-5 font-medium text-gray-900 mb-4">
            Default Payment Method
          </p>
          <PaymentMethod
            key={defaultPaymentMethod.id}
            payment_method={defaultPaymentMethod}
            customer={customer}
            organization={organization}
          />
        </>
        }

        {otherPaymentMethods.length > 0 && <>
          <p className="mt-5 text-lg leading-5 font-medium text-gray-900 mb-4">
            Other Payment Methods
          </p>
          {otherPaymentMethods.map(payment_method => (
            <PaymentMethod
              key={payment_method.id}
              payment_method={payment_method}
              customer={customer}
              organization={organization}
            />
          ))}
        </>}
      </ul>
    </>
  )
}


const PaymentMethod = ({ payment_method, customer, organization }) => {
  const isDefault = payment_method.id == customer.invoice_settings.default_payment_method

  return <>
    <li key={payment_method.id} className={`mb-5 col-span-1 rounded-lg shadow divide-gray-200 ${isDefault ? "bg-green-50" : "bg-white"} `}>
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center justify-between">
            <h3 className="text-gray-900 text-sm font-medium truncate">{payment_method.card.brand} </h3>
            {isDefault &&
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                Default Payment Method
              </span>
            }
          </div>
          <h3>**** **** **** {payment_method.card.last4}</h3>
          <p className="mt-1 text-gray-500 text-sm truncate">Expires: {payment_method.card.exp_month}/{payment_method.card.exp_year}</p>
        </div>
      </div>
      {!isDefault &&
        <div>
          <div className="-mt-px flex center">
            <a
              href={`/profile/update_card?customer_id=${customer.id}&payment_method_id=${payment_method.id}`}
              data-method="post"
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-green-50 hover:bg-green-600"
            >
              Set As default
            </a>
            <a
              href={`/profile/destroy_card?customer_id=${customer.id}&payment_method_id=${payment_method.id}`}
              data-method="post"
              type="button"
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-red-50 hover:bg-red-600"
            >
              Remove Payment Method
            </a>
          </div>
        </div>
      }
    </li>
  </>
}

export default PaymentMethodManager
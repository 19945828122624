import React from "react";
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useCheckout } from './StateManagement';
import { useState } from 'react';
import { NumericFormat } from "react-number-format";


/* 
    {id: 1, name: 'Shipping Option 1', cost: 3, delivery_time: '2-5 days'}
*/
export const ShippingOptionSection = () => {
    const { state: { shippingOptions }, } = useCheckout();

    return (
        <div>
            <h4 className="text-lg text-gray-900 font-medium">Delivery Method</h4>
            <div className="mt-6 grid grid-cols-1 gap-y-6 lg:grid-cols-3 sm:gap-x-4">
                {shippingOptions.map((s, i) => {
                    return <ShippingOptionCard key={i} method={s} />;
                })}
                
            </div>
        </div>



    );
};

const ShippingOptionCard = ({ method }) => {
    const { setShippingOption, state: { shippingOption }, } = useCheckout();

    const handleClick = () => {
        setShippingOption(method);
    };

    const active = shippingOption === method;

    return (
        <div>
            <div
            onClick={handleClick}
            className={`group relative flex items-center justify-between cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none 
                ${active ? 'border-indigo-600 ring-2 ring-indigo-600' : ''}
            `}
            style={{ height: '150px', position: 'relative' }}
            >
                    <div className="flex flex-auto flex-col w-32">
                        <span className="block text-sm font-medium text-gray-900">{method.name}</span>
                        {method.cost_cents === 0 ? (
                            <span className="mt-auto flex items-center text-sm text-gray-500 w-24 select-none">Free</span>
                        ) : (
                            <NumericFormat className="mt-auto flex items-center text-sm text-gray-500 w-24 select-none" displayType="text" value={method.cost_cents / 100} prefix={'$'} decimalScale={2} style={{border: 'none'}} />
                        )}
                        
                        <span className="mt-6 text-sm font-medium text-gray-900">{method.delivery_time}</span>
                    </div>
                {active && (<CheckCircleIcon className="h-6 w-6 text-indigo-600 absolute top-2 right-2"/>) }
            </div>
        </div>
    )

}
import React, { useContext, createContext, useReducer } from 'react';

const initialState = {
  formSetting: {
    include_address_fields: false,
    require_address_fields: false,
    include_membership_id_field: false,
    require_membership_id_field: false,
  },
};

const formSettingsReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_FIELDS':
      return {
        ...state,
        formSetting: { ...state.formSetting, ...action.formSetting },
      };
    default:
      return state;
  }
};

const FormSettingsContaxt = createContext(null);

export const useFields = () => {
  const [state, dispatch] = useContext(FormSettingsContaxt);

  const toggleAddressFields = (shouldInclude) => {
    if (shouldInclude) {
      dispatch({
        type: 'TOGGLE_FIELDS',
        formSetting: { include_address_fields: shouldInclude },
      });
    } else {
      dispatch({
        type: 'TOGGLE_FIELDS',
        formSetting: {
          include_address_fields: shouldInclude,
          require_address_fields: shouldInclude,
        },
      });
    }
  };

  const toggleMembershipIdField = (shouldInclude) => {
    if (shouldInclude) {
      dispatch({
        type: 'TOGGLE_FIELDS',
        formSetting: { include_membership_id_field: shouldInclude },
      });
    } else {
      dispatch({
        type: 'TOGGLE_FIELDS',
        formSetting: {
          include_membership_id_field: shouldInclude,
          require_membership_id_field: shouldInclude,
        },
      });
    }
  };

  const toggleRequireAddressFields = (shouldRequire) => {
    dispatch({
      type: 'TOGGLE_FIELDS',
      formSetting: { require_address_fields: shouldRequire },
    });
  };

  const toggleRequireMembershipIdField = (shouldRequire) => {
    dispatch({
      type: 'TOGGLE_FIELDS',
      formSetting: { require_membership_id_field: shouldRequire },
    });
  };

  return {
    state,
    dispatch,
    toggleAddressFields,
    toggleRequireAddressFields,
    toggleMembershipIdField,
    toggleRequireMembershipIdField,
  };
};

export const FormSettingsProvider = (props) => {
  const [state, dispatch] = useReducer(formSettingsReducer, {
    ...initialState,
    formSetting: props.formSetting,
    fund: props.fund,
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <FormSettingsContaxt.Provider value={value} {...props} />;
};

import React from 'react';
import { CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { useCheckout } from './StateManagement';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Rubik", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6B6B6B',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

export const PaymentMethodSection = () => {
  const {
    setPaymentMethod,
    state: { paymentMethods, paymentMethod, user },
  } = useCheckout();

  // console.log("Payment Methods " + paymentMethods);

  const handleNewMethod = () => {
    setPaymentMethod({ id: null });
  };

  const useCard = () => {
    setPaymentMethod({ id: paymentMethods[0].id });
  };

  if (user.id && paymentMethods && paymentMethod) {
    return (
      <>
        <div className="my-6 ">
          <label className="block text-sm font-medium font-body leading-5 text-gray-700">
            Payment Method*
          </label>

          {paymentMethods.map((p, i) => {
            return <PaymentMethodCard key={i} method={p} />;
          })}
          <p className="">
            {' '}
            or{' '}
            <span
              onClick={handleNewMethod}
              className="text-sm cursor-pointer text-indigo-700"
            >
              {' '}
              use new card{' '}
            </span>
          </p>

        </div>
      </>
    );
  }

  return (
    <>
      <div className="my-6 ">
        <label className=" text-sm font-medium font-body leading-5 text-gray-700 flex justify-between">
          <div> Card Info* </div>
        </label>
        <div className="p-3 mt-1 border rounded">
          <CardElement options={CARD_OPTIONS} />
          {/* <PaymentElement /> */}
        </div>
        {paymentMethods ? (
          <p className=""> {' '}or{' '}
            <span
              onClick={useCard}
              className="text-sm cursor-pointer text-indigo-700"
            > {' '}use previously saved card{' '}
            </span>
          </p>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const PaymentMethodCard = ({ method }) => {
  const {
    setPaymentMethod,
    state: { paymentMethod },
  } = useCheckout();

  const handleClick = () => {
    setPaymentMethod(method);
  };
  const active = paymentMethod === method.id;

  return (
    <>
      <div
        onClick={handleClick}
        className={`p-2 mt-3 border rounded flex justify-between hover:bg-green-100 cursor-pointer ${active ? 'bg-green-50' : ''
          }`}
      >
        <div className="flex">
          <div className="w-5 text-green-600 mr-3">
            {!active || <CheckIcon />}
          </div>

          <div className="border rounded px-2 ">{method.card.brand}</div>
          <div className="pl-4">**** **** **** {method.card.last4}</div>
        </div>

        <div className="">
          {method.card.exp_month} / {method.card.exp_year}
        </div>
      </div>
    </>
  );
};

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

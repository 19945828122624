import React, { useRef } from 'react';
import { useFunds } from './StateManagement';
import { ItemTypes } from './ItemTypes';

import { FundHeading, FundStats, FundFooter } from './CardElements';

export const FundCard = ({ fund, index }) => {
  const { state, useDrag, useDrop, moveFund, updatePositions } = useFunds();
  const { draggable } = state;

  const ref = useRef(null);

  const [{ opacity }, drag] = useDrag({
    item: { id: fund.id, index },
    type: ItemTypes.FUND,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    canDrag: () => draggable,
  });

  const [, drop] = useDrop({
    accept: ItemTypes.FUND,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 100) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 100) return;

      // Time to actually perform the action
      moveFund(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item) {
      updatePositions(item);
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className="relative block p-5 pb-3 mt-4 lg:mt-0 shadow-md rounded bg-white hover:shadow-lg focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
    >
      <div className="flex flex-col justify-between h-full">
        <FundHeading
          imageUrl={fund.image_url}
          name={fund.name}
          fundType={fund.fund_type}
        />

        <FundStats
          transactionTotalDollarsHuman={fund.transaction_total_dollars_human}
          transactionsCount={fund.transactions_count}
        />

        <FundFooter status={fund.status} path={fund.fund_path} />
      </div>
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { generateId } from '../../utils/genIds';
import Container from './DropArea';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// max file size in bytes (10MB)
const MAX_FILE_SIZE = 10000000;

const FileArea = (props) => {
  const {
    name,
    hasMaxSize = true,
    buttonText = 'Change',
    acceptedTypes = [],
  } = props;

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Container buttonText={buttonText} name={name} acceptedTypes={acceptedTypes} />
      </DndProvider>
    </>
  );
};

export default FileArea;

import React, { useContext, createContext, useReducer } from 'react';
import { generateId } from '../../utils/genIds';

const newField = {
  id: null,
  objectId: null,
  label: '',
  field_type: '',
  required: false,
  destroy: false,
};

const newDropdownOption = {
  id: null,
  objectId: null,
  fieldObjectId: null,
  value: '',
  destroy: false,
};

const initialState = {
  resource: {
    include_address_fields: false,
    require_address_fields: false,
  },
  resource_name: '',
  fields: [],
};

const fieldsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_FIELD':
      return { ...state, fields: [...state.fields, action.field] };
    case 'ADD_DROPDOWN_OPTION':
      return {
        ...state,
        dropdown_options: [...state.dropdown_options, action.dropdownOption],
      };
    case 'SET_DROPDOWN_OPTIONS':
      return { ...state, dropdown_options: action.dropdownOptions };
    case 'SET_FIELDS':
      return { ...state, fields: action.fields };
    case 'TOGGLE_ADDRESS_FIELDS':
      return { ...state, resource: { ...state.resource, ...action.addressFields } };
    default:
      return state;
  }
};

const FieldsContext = createContext(null);

export const useFields = () => {
  const [state, dispatch] = useContext(FieldsContext);

  const addField = () => {
    dispatch({
      type: 'ADD_FIELD',
      field: { ...newField, objectId: generateId() },
    });
  };

  const removeField = (field) => {
    // const dueLevels = state.dueLevels
    // remove a due level if it does exist
    // toggle destroy a due level if it does exist
    if (field.id === null) {
      const newfields = state.fields.filter(
        (f) => f.objectId != field.objectId
      );
      dispatch({ type: 'SET_FIELDS', fields: newfields });
    } else {
      const newfields = state.fields.map((f) => {
        if (f.objectId === field.objectId) {
          return { ...f, destroy: true };
        } else {
          return { ...f };
        }
      });
      dispatch({ type: 'SET_FIELDS', fields: newfields });
    }
  };

  const toggleAddressFields = (shouldInclude) => {
    if (shouldInclude) {
      dispatch({
        type: 'TOGGLE_ADDRESS_FIELDS',
        addressFields: { include_address_fields: shouldInclude },
      });
    } else {
      dispatch({
        type: 'TOGGLE_ADDRESS_FIELDS',
        addressFields: {
          include_address_fields: shouldInclude,
          require_address_fields: shouldInclude,
        },
      });
    }
  };

  const toggleRequireAddressFields = (shouldRequire) => {
    dispatch({
      type: 'TOGGLE_ADDRESS_FIELDS',
      addressFields: {
        require_address_fields: shouldRequire,
      },
    });
  };

  const setFieldType = (field, input_type) => {
    const newFields = state.fields.map((f) => {
      if (f.objectId === field.objectId) return { ...f, input_type };

      return { ...f };
    });
    dispatch({ type: 'SET_FIELDS', fields: newFields });

    if (input_type === 'drop down') {
      const dropdownOption = {
        ...newDropdownOption,
        fieldObjectId: field.objectId,
      };
      dispatch({
        type: 'ADD_DROPDOWN_OPTION',
        dropdownOption,
      });
    }
  };

  const addOption = (field) => {
    dispatch({
      type: 'ADD_DROPDOWN_OPTION',
      dropdownOption: { ...newDropdownOption, fieldObjectId: field.objectId },
    });
  };

  const removeOption = (option) => {
    if (option.id === null) {
      const newOptions = state.dropdown_options.filter(
        (o) => o.objectId != option.objectId
      );
      dispatch({ type: 'SET_DROPDOWN_OPTIONS', dropdownOptions: newOptions });
    } else {
      const newOptions = state.dropdown_options.map((o) => {
        if (o.objectId === option.objectId) {
          return { ...o, destroy: true };
        } else {
          return { ...o };
        }
      });
      dispatch({
        type: 'SET_DROPDOWN_OPTIONS',
        dropdownOptions: newOptions,
      });
    }
  };

  return {
    state,
    dispatch,
    addField,
    removeField,
    toggleAddressFields,
    toggleRequireAddressFields,
    setFieldType,
    removeOption,
    addOption,
  };
};

export const FieldsProvider = (props) => {
  // added  the form state

  const [state, dispatch] = useReducer(fieldsReducer, {
    ...initialState,
    resource: props.resource,
    resource_name: props.resource_name,
    fields: props.fields || [],
    dropdown_options: props.dropdown_options || [],
    input_types: props.input_types,
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  return <FieldsContext.Provider value={value} {...props} />;
};

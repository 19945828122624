import React from "react";
import {useCheckout} from "./StateManagement";

export const Input = ({
  type,
  label,
  name,
  isRequired,
  selectOptions = null,
  isChecked,
  context,
}) => {
  const { updateUserAttribute, toggleSameAsBilling, setShippingAddress, setBillingAddress, state } = useCheckout();
  const { user, shipping_address, billing_address, errors } = state;
  const handleCheckboxChange =  (event) => {
    toggleSameAsBilling( );
  };

  const contextHandlers = {
    user: updateUserAttribute,
    shipping: setShippingAddress,
    billing: setBillingAddress,
  };

  const handleChange = (e) => {
    const updateFunction = contextHandlers[context];
    if (updateFunction) {
      updateFunction(name, e.target.value);
    }
  };
  const myerrors = errors[name] || [];

  const value = 
    context === 'shipping' ? shipping_address[name] : 
    context === 'billing' ? billing_address[name] :
    user[name];


  const renderDropdown = () => {
    return (
      <select
        type={type}
        value={value || ''}
        name={name}
        required={isRequired}
        context={context}
        onChange={handleChange}
        className="form-select block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "
      >
        <option value="" disabled hidden>
          Choose one
        </option>
        {selectOptions.length > 0 &&
          selectOptions.map((o, i) => (
            <option key={i} value={o.value}>
              {o.text}
            </option>
          ))}
      </select>
    );
  };

  return (
    <>
      <div className="py-2 sm:col-span-3">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {' '}
          {label}
          {isRequired ? '*' : ''}
        </label>
        <div className={type === 'checkbox' ? "mt-1 rounded-md" : "mt-1 rounded-md shadow-sm"}>
          {type === 'dropdown' ? (
            renderDropdown()
          ) : (
            <input
              type={type}
              value={value || ''}
              name={name}
              required={isRequired}
              context={context}
              onChange={type === 'checkbox' ? handleCheckboxChange : handleChange }
              checked={isChecked}
              className={type === 'checkbox' ? "form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" : "block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "}
            />
          )}

          {myerrors.length > 0 ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            ''
          )}
        </div>
        {myerrors.map((e, i) => {
          return (
            <p key={i} className="mt-2 text-sm text-red-600">
              {e}
            </p>
          );
        })}
      </div>
    </>
  );
};
import React, { useState, useLayoutEffect } from 'react'
// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const StripeSDKLoader = ({ stripe_publishable_key, children }) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [stripe, setStripe] = useState(null);
  // const [options, setOptions] = useState(null);

  useLayoutEffect(() => {
    const promise = loadStripe(stripe_publishable_key)
    // const options = {
    //   mode: 'payment',
    //   currency: 'usd',
    //   amount: 1099,
    // };
    setStripe(promise)
    setSdkLoaded(true)
    // setOptions(options)
  }, [])

  return (
    sdkLoaded ? (
      <Elements stripe={stripe}>
        {children}
      </Elements>
    ) : (
      <p>Loading ...</p>
    )
  )
}
export { StripeSDKLoader }
import React from "react";
import { StripeFormProvider, useForm } from "./StateManagement";
import { COUNTRY_SELECT_OPTIONS } from "../../utils/countrySelectOptions";
import { ReceiptComponent } from "./ReceiptPage";
import { StripeSDKLoader } from "../../utils/StripeSdkLoader";
import { DuesForm } from "./DuesForm";
import { SingleChargeForm } from "./SingleChargeForm";
import { Input, SubmitBtn, CustomFormFields, BaseErrors } from "./FormInputs";
import { PaymentMethodSection } from "./PaymentComponents";
import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {
  const { state, dispatch } = useForm();
  const { fund, feeModel, formSetting, recaptcha_site_key, recaptcha_enabled } = state;
  const recaptchaRef = React.createRef();

  let FormInstance = fund.fund_type === "dues" ? DuesForm : SingleChargeForm;

  let recaptchaChange = (value) => {
    dispatch({ type: "UPDATE_RECAPTCHA", recaptcha_response: value });
  }

  return (
    <FormInstance>
      <div className="grid grid-cols-1 gap-x-4 mt-1 sm:grid-cols-6">
        <Input
          type="text"
          label="First Name"
          name="first_name"
          isRequired={true}
        />
        <Input
          type="text"
          label="Last Name"
          name="last_name"
          isRequired={true}
        />
        <Input type="email" label="Email" name="email" isRequired={true} />
        <Input type="phone" label="Phone" name="phone" isRequired={false} />

        <AddressFields />
      </div>
      <CustomFormFields />
      <PaymentMethodSection />
      <SaveBillingInfo />
      <BaseErrors />
      {feeModel.feeType != "union_pays" ? <Summary /> : ""}
      {recaptcha_enabled && (
        <div className="pb-2">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptcha_site_key}
            onChange={recaptchaChange}
          />
        </div>
      )}
      <SubmitBtn />
      <LegalCopy />
    </FormInstance>
  );
};

const AddressFields = () => {
  const { state } = useForm();
  const { formSetting } = state;

  if (!formSetting.include_address_fields) return null;

  return (
    <>
      <Input
        type="text"
        label="Address"
        name="line1"
        isRequired={formSetting.require_address_fields}
      />
      <Input
        type="text"
        label="Apartment, suite, etc"
        name="line2"
        isRequired={false}
      />
      <Input
        type="text"
        label="City"
        name="city"
        isRequired={formSetting.require_address_fields}
      />
      <Input
        type="text"
        label="State / Province"
        name="region"
        isRequired={formSetting.require_address_fields}
      />
      <Input
        type="dropdown"
        label="Country"
        name="country"
        isRequired={formSetting.require_address_fields}
        selectOptions={COUNTRY_SELECT_OPTIONS}
      />
      <Input
        type="text"
        label="ZIP / postal code"
        name="postalcode"
        isRequired={formSetting.require_address_fields}
      />
    </>
  );
};

const SaveBillingInfo = () => {
  const { state, toggleSaveBillingInfo } = useForm();
  const { autoPay, user, saveBillingInfo } = state;
  return (
    <>
      {user.id ? (
        ""
      ) : (
        <>
          {!!autoPay || (
            <div className="flex items-center mt-6 text-sm text-gray-600">
              <input
                type="checkbox"
                name="save_billing_info"
                id="save-billing-info"
                checked={saveBillingInfo || false}
                onChange={toggleSaveBillingInfo}
              />
              <label className="ml-2" htmlFor="save-billing-info">
                Create Account and Save Billing Info?
              </label>
            </div>
          )}
          {!saveBillingInfo || (
            <Input
              type="password"
              label="Choose Your Account Password"
              name="password"
              isRequired={true}
            />
          )}
        </>
      )}
    </>
  );
};

const Summary = () => {
  const { state } = useForm();
  const { chargeData, fund } = state;
  return (
    <>
      <p className="text-xs text-gray-600 mt-6">Summary</p>
      <div className="container p-3 mb-4 bg-gray-50 m-t-3">
        <p className="text-xs text-gray-600">
          Payment:{" "}
          {chargeData.amount > 0 ? `$` + chargeData.amount.toFixed(2) : ""}
          {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
        </p>
        <p className="text-xs text-gray-600">
          Processing Fee:{" "}
          {chargeData.amount > 0 ? `$` + chargeData.totalFee : ""}
          {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
        </p>
        <hr className="my-2 border-t border-gray-200" />
        <p className="text-xs text-gray-700">
          <strong>
            Total: {chargeData.amount > 0 ? `$` + chargeData.total : ""}
            {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
          </strong>
        </p>
      </div>
    </>
  );
};

const LegalCopy = () => {
  const { autoPay } = useForm();
  return (
    <>
      <aside className="mt-5 text-xs text-gray-600">
        <span>
          By confirming your {autoPay ? "subscription" : "transaction"}, you
          allow Unionly to charge your card for this amount{" "}
          {autoPay ? "and future payments for the same amount " : ""}in
          accordance with their{" "}
        </span>
        <a className="underline" href="/terms-of-use">
          terms
        </a>{" "}
        and{" "}
        <a className="underline" href="/privacy-policy">
          privacy policy
        </a>{" "}
        .
      </aside>
    </>
  );
};

const FormPage = () => {
  const {
    state: { completed },
  } = useForm();
  return completed ? <ReceiptComponent /> : <Form />;
};

const StripeForm = (props) => {
  const stripe_publishable_key = props.stripe_publishable_key;
  return (
    <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
      <StripeFormProvider {...props} >
        <FormPage />
      </StripeFormProvider>
    </StripeSDKLoader>
  );
};

export default StripeForm;

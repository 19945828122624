import React from 'react';

const AnchorLink = (props) => {
  const { targetId, text, className } = props;

  const handleClick = (e) => {
    e.preventDefault;
    const targetEl = document.getElementById(targetId);

    if (!!targetEl) targetEl.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <a
      onClick={handleClick}
      className={`relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium rounded-md text-gray-800 bg-yellow-800  hover:bg-yellow-700 hover:text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 transition ease-in-out duration-150 cursor-pointer ${className}`}
    >
      {text}
    </a>
  );
};

export default AnchorLink;

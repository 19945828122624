export function currencyFormat(num) {
  // if the number start with a negative sign, mark it remove it and add it before the dollar sign
  if (num.toString().startsWith('-')) {
    return '-$' + num.toString().replace(/^-/, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function prettyDate(date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}
import React from 'react';
import { useForm } from "./provider";
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/20/solid'

export const PickPaymentMethodPage = (props) => {
  const { state } = useForm();
  const { offline_payments_enabled } = state;
  return (
    <>
      <p className='text-gray-400 text-md uppercase'>
        amount due: <span className=''> ${state.chargeData.total} </span>
      </p>
      <h2 className='text-3xl '>
        Pick a Payment Method
      </h2>
      <div className="grid sm:grid-cols-3 pt-4 gap-4">
        {offline_payments_enabled && <OfflineCard goToNamedStep={props.goToNamedStep} /> }
        <CreateNewCard goToNamedStep={props.goToNamedStep} />
        {state.paymentMethods.length > 0 ? (
          state.paymentMethods.map((paymentMethod, index) => { return <Card key={index} paymentMethod={paymentMethod} goToNamedStep={props.goToNamedStep} /> })
        ) : null}
      </div>
    </>
  )
}


const OfflineCard = ({ goToNamedStep }) => {
  const { dispatch } = useForm();
  const handleClick = () => {
    dispatch({type: "SET_OFFLINE_PAYMENT", offlinePayment: true })
    goToNamedStep("card_payment_page")
  }

  return (
    <div onClick={handleClick} className="relative hover:bg-gray-50 flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none border-transparent undefined transition ease-in-out transform hover:scale-105">
      <span className="flex flex-1">
        <span className="flex flex-col">
          <span className="block text-sm font-medium text-gray-900">Make Offline Payment</span>
          <span className="mt-1 flex items-center text-sm text-gray-500">ie: check or money order</span>
          {/* <span className="mt-6 text-sm font-medium text-gray-900">$5.00</span> */}
        </span>
      </span>
      {/* <CheckCircleIcon className='h-6 w-6 text-indigo-600' /> */}
      <span className="pointer-events-none absolute -inset-px rounded-lg border-indigo-500 border-2" aria-hidden="true" x-description=""> </span>
    </div>
  )
}

const CreateNewCard = ({ goToNamedStep }) => {
  return (
    <div onClick={() => goToNamedStep("create_payment_method_page")} className="relative hover:bg-gray-50 flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none border-transparent undefined transition ease-in-out transform hover:scale-105">
      <span className="flex flex-1">
        <span className="flex flex-col">
          <span className="block text-sm font-medium text-gray-900">New Payment Method</span>
          <span className="mt-1 flex items-center text-sm text-gray-500">add a card to make the payment</span>
          {/* <span id="delivery-method-0-description-1" class="mt-6 text-sm font-medium text-gray-900">$5.00</span> */}
        </span>
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-indigo-600">
        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
      </svg>

      <span className="pointer-events-none absolute -inset-px rounded-lg border-indigo-500 border-2" aria-hidden="true" x-description=""> </span>
    </div>
  )
}


const Card = ({ paymentMethod, goToNamedStep }) => {
  const { card } = paymentMethod

  const { setPaymentMethod } = useForm();
  const handleSubmit = (e) => {
    setPaymentMethod(paymentMethod)
    goToNamedStep("card_payment_page")
  }
  return (
    <div onClick={handleSubmit} className="relative hover:bg-gray-50 flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none border-transparent undefined transition ease-in-out transform hover:scale-105">
      <span className="flex flex-1">
        <span className="flex flex-col">
          <span className=" text-sm font-medium text-gray-900 flex">
            {card.brand === "visa" ? (
              <svg className="rounded" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h32v32H0z" fill="#00579f"></path><g fill="#fff" fillRule="nonzero"><path d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z"></path><path d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z"></path></g></g></svg>
            ) :
              <p className="ml-3 align-middle	content-center items-center	">
                {card.brand}
              </p>
            }
          </span>
          <span className="mt-1 flex items-center text-sm text-gray-500">ending in {card.last4}</span>
          <span className="mt-6 text-sm font-medium text-gray-900"> {card.exp_month}/{card.exp_year} </span>
        </span>
      </span>
      {/* <CheckCircleIcon className='h-5 w-5 text-indigo-600 ' /> */}
      <span className="pointer-events-none absolute -inset-px rounded-lg border-indigo-500 border-2" aria-hidden="true" x-description=""> </span>
    </div>
  )
}

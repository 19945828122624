import React from 'react';
import Dropdown from './dropdown'
import { useRef, useState } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

const UpdateMembershipModal = (props) => {
  const { due_levels, fee_model, current_user, subscription } = props;
  const [open, setOpen] = useState(false)
  const [selectedDueLevel, setSelectedDueLevel] = useState(null)

  return (
    <>
      <div>
        <button
          className="mt-2 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          onClick={() => setOpen(true)}>Change Membership</button>
        <Model
          isOpen={open}
          setOpen={setOpen}
          selectedDueLevel={selectedDueLevel}
          setSelectedDueLevel={setSelectedDueLevel}
          due_levels={due_levels}
          fee_model={fee_model}
          subscription={subscription}
          currentUser={current_user}
        />
      </div>
    </>
  );
};



function Model({ isOpen, setOpen, due_levels, fee_model, currentUser, subscription, selectedDueLevel, setSelectedDueLevel }) {

  const cancelButtonRef = useRef(null)

  const handleClick = (e) => {
    e.preventDefault()
  }

  return (
    <Transition as="div" show={isOpen}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Switch Membership Amount
                    </DialogTitle>
                    <div className="mt-2">
                      <Dropdown
                        due_levels={due_levels}
                        fee_model={fee_model}
                        selectedDueLevel={selectedDueLevel}
                        setSelectedDueLevel={setSelectedDueLevel}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <a
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    type="button"
                    data-confirm="Are you sure you want change your membership?"
                    rel="nofollow"
                    onClick={handleClick}
                    data-method="patch"
                    href={`/profile/${currentUser.id}/subscriptions/${subscription.id}?due_level_id=${selectedDueLevel?.id}`}>
                    Change Membership
                  </a>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}


export default UpdateMembershipModal;

import React from 'react';
import ReactTooltip from 'react-tooltip';
// https://www.npmjs.com/package/react-tooltip

const ToolTip = (props) => {
  const { dataFor, message, type } = props;

  return (
    <>
      <ReactTooltip multiline id={dataFor} type={type} />
    </>
  );
};

export default ToolTip;

import React, { useState, useEffect, useRef } from 'react';
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';

const TransactionFilterForm = (props) => {
  const {
    startDate,
    endDate,
    searchValue = "",
    basePath = '/dashboard/reports',
  } = props;

  const [startDateVal, setStartDateVal] = useState((!!startDate ? moment.unix(Number(startDate)).format("YYYY-MM-DD") : null));
  const [endDateVal, setEndDateVal] = useState((!!endDate ? moment.unix(Number(endDate)).format("YYYY-MM-DD") : null));
  // console.log("startdate", (!!startDate ? moment.unix(Number(startDate)).format("YYYY-MM-DD") : null))

  const handleDatesChanged = ({ startDate, endDate }) => {
    if (!!startDate && !!endDate) {
      setStartDateVal(moment(startDate).utc(true).startOf('day').format("YYYY-MM-DD"));
      setEndDateVal(moment(endDate).utc(true).endOf('day').format("YYYY-MM-DD"));
    } else {
      setStartDateVal(null);
      setEndDateVal(null);
    }
  };

  const setUnixStart = (date) => {
    if (!date) return null
    return moment(date).utc(true).startOf('day').format('X')
  }
  const setUnixEnd = (date) => {
    if (!date) return null
    return moment(date).utc(true).endOf('day').format('X')
  }

  // ?charge_time_end=1676159999&charge_time_start=1675814400&search_value=asdfsdf
  return (
    <form action={basePath} method="get" >
      {startDateVal &&
        <input type="hidden"
          id="charge_time_start"
          name="charge_time_start"
          value={setUnixStart(startDateVal)}
        />
      }
      {endDateVal &&
        <input type="hidden"
          id="charge_time_end"
          name="charge_time_end"
          value={setUnixEnd(endDateVal)}
        />
      }
      <div className="grid sm:grid-cols-3 mt-3 gap-4">
        <div className="flex flex-col">
          <h2 className="text-sm font-semibold text-gray-700 mb-2">
            Select Date Range:
          </h2>
          <Datepicker
            inputClassName={"border border-gray-300 rounded-md shadow-sm"}
            primaryColor={"indigo"}
            value={{ startDate: startDateVal, endDate: endDateVal }}
            onChange={handleDatesChanged}
          />
          <div className="text-xs text-gray-500 mt-1">
            All transactions in EST Time Zone
          </div>
        </div>
        <div className="flex flex-col">
          <h2 className="text-sm font-semibold text-gray-700">Search:</h2>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              name="search_value"
              defaultValue={searchValue}
              className="form-input block transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 disabled:opacity-50"
              placeholder="ex: Jane Doe"
            />
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Search ID, name, or email
          </div>
        </div>

        <div className="justify-end flex justify-items-end	items-center">

          <button
            type='submit'
            className={`inline-flex justify-center items-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-indigo-700 border border-transparent rounded-md hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 ease-in-out duration-150`}
          >
            <svg
              className="refresh w-4 h-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clipRule="evenodd"
              ></path>
            </svg>
            Update Report
          </button>
        </div>
      </div>
    </form>
  );
};

export default TransactionFilterForm;

import React, { useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useInterval } from '../../utils/useInterval';


export default ({ invoicing_fund, member_count, due_date, reminders }) => {
  const invoicingFund = invoicing_fund;
  const [isUploadingMembers, setIsUploadingMembers] = useState(invoicingFund.uploading_members);
  const [memberCount, setMemberCount] = useState(member_count);

  useInterval(() => {
    if (isUploadingMembers) {
      fetch(`/dashboard/invoicing/${invoicingFund.id}.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name=csrf-token]').content,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then((response) => {
          setIsUploadingMembers(response.uploading_members);
          setMemberCount(response.member_count)
        })
        .catch((error) => {
          // console.log(error.message));
        });
    }
  }, isUploadingMembers ? 1500 : null);

  return (
    <div className="py-10 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="max-w-4xl mx-auto p-4 bg-white rounded-md border ">
        <div className="px-4 py-5 sm:p-0 mt-5">
          {isUploadingMembers ? <StillUploading /> : <FinalizeStep invoicingFund={invoicingFund} memberCount={memberCount} due_date={due_date} reminders={reminders} />}
        </div>
      </div>
    </div>
  )
};

const StillUploading = () => {
  return (
    <div className="">
      <div className="text-center">
        <svg className="mt-3 mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        <div className="text-3xl">
          Almost Finished
        </div>
        <p className='text-gray-500'>Unionly is Currently Uploading your Members, should be just a few minutes</p>
        <p className='text-gray-500'>(page will update when finished)</p>
      </div>
      <Player
        autoplay
        loop
        speed={0.65}
        src="https://assets8.lottiefiles.com/packages/lf20_SeIODexgqB.json"
        style={{ height: '300px', width: '300px' }}
      >
      </Player>

      {/* display the schedule for due dates and reminder dates */}

    </div>
  )
}

const FinalizeStep = ({ invoicingFund, memberCount, due_date, reminders }) => {
  return (
    <>
      <div className="text-center">
        <svg className="mt-3 mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
        <div className="text-3xl">
          Finished Uploading {memberCount} Members
        </div>
      </div>
      <div className='max-w-xl mx-auto pt-4'>
        <p>Name: {invoicingFund.name}</p>
        <p>number of uploaded members: {memberCount}</p>
        <p className='text-xl py-2 bolder'>Schedule: </p>
        <p>The <b>due date</b> will be created on the <b>{due_date}</b> of every month. </p>
        <p>The members will be <b>reminded</b> to pay their dues: </p>
        <ul className='list-disc list-inside pl-4'>
          {reminders.map((reminder) => {
            return (
              <li key={reminder.id}> <b> {reminder.days_before_or_after} </b> day{reminder.days_before_or_after != 1 ? "s" : ""}
                {reminder.reminder_type == "overdue" ? " after " : " before "}
                the due day</li>
            )

          })}
        </ul>
      </div>
      <span className="flex justify-center pt-6">
        <a className="inline-flex justify-center py-2 px-20 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
          data-confirm="Are you sure?" rel="nofollow" data-method="POST" href={`/dashboard/invoicing/${invoicingFund.id}/activate`}>Activate</a>
      </span>
    </>
  )
}

//
// wrapper around axios to include Rails CSRF token
//
import axios from 'axios';

const token = document.querySelector('[name="csrf-token"]') || {
  content: 'no-csrf-token',
};

export const createIntent = (params) => {
  return axiosInstance.get('/transactions/create_intent', {
    params,
  });
};

export const getCoupon = (params) => {
  return axiosInstance.post('/signup/coupon', {
    ...params
  });
};

export const updateFundPositions = (params) => {
  return axiosInstance.post('/dashboard/funds/update_positions', {
    ...params
  });
};

export const updateProductPositions = (params) => {
  return axiosInstance.post('/dashboard/products/update_positions', {
    ...params
  });
};

export const postRequest = (url, params) => {
  return axiosInstance.post(url, {
    ...params
  });
};

const axiosInstance = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content,
    },
  },
});

export default axiosInstance;
import React, { useState } from "react";
import { TrashIcon } from '@heroicons/react/24/solid';
import { useCheckout } from './StateManagement';
import { useEffect } from 'react';
import { NumericFormat } from "react-number-format";
import { calcStoreFee } from "../../utils/storeFee";
// import { calcFee } from "../../utils/stripeFee";

/*
const cart =
    {
        productVariants:
        [
            0: {id: 1, name: 'Small', price: {cents: 434, currency_iso: 'USD'}, product: {name: 'Test Product 1', preview_image_url: 'google.com'}}},
            1: {id: 2, name: 'Small', price: {cents: 1000, currency_iso: 'USD'}, product: {name: 'Test Product 3', preview_image_url: 'google.com'}}}
        ],
        quantity: {1: 1, 2: 1},
    }
*/

export const OrderSummarySection = () => {
  const { setOrderSummary, removeCartItem, shippingEnabled, state: { cart, shippingOption, orderSummary, feeModel }, } = useCheckout();

  useEffect(() => {
    const calculateSubtotal = () => {
      return cart.productVariants.reduce((sum, pv) => {
        const quantity = cart.quantity[pv.id] || 0;
        return sum + (pv.price.cents * quantity);
      }, 0);
    };

    const newSubtotal = calculateSubtotal();
    const shippingCost = shippingOption?.cost_cents || 0;
    const newFees = calcStoreFee((newSubtotal + shippingCost), feeModel);
    const newTotal = newSubtotal + shippingCost + newFees;

    setOrderSummary({
      subtotal: newSubtotal,
      shipping_cost: shippingCost,
      fees: newFees,
      total: newTotal,
    });

  }, [cart, shippingOption]);

  const { subtotal, shipping_cost, fees, total } = orderSummary;

  const handleRemoveItem = (pvId, quantity) => {
    removeCartItem(pvId, quantity);
  };

  return (
    <div className="mt-10 lg:mt-0">
      <h2 className="text-lg font-medium text-gray-900">Order summary</h2>

      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <h3 className="sr-only">Items in your cart</h3>
        <ul role="list" className="divide-y divide-gray-200">
          {cart.productVariants.map((pv) => (
            <li key={pv.id} className="flex px-4 py-6 sm:px-6">
              <div className="flex-shrink-0">
                <img alt={pv.product.name} src={pv.product.preview_image_url} className="w-20 rounded-md" />
              </div>

              <div className="ml-6 flex flex-1 flex-col">
                <div className="flex">
                  <div className="min-w-0 flex-1">
                    <h4 className="text-sm">
                      <a href="#" className="font-medium text-gray-700 hover:text-gray-800">
                        {pv.product.name}
                      </a>
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">{pv.name}</p>
                  </div>

                  <div className="ml-4 flow-root flex-shrink-0">
                    <button
                      type="button"
                      className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                      onClick={() => handleRemoveItem(pv.id, 0)}
                    >
                      <span className="sr-only">Remove</span>
                      <TrashIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="flex flex-1 items-end justify-between pt-2">
                  <p className="mt-1 text-sm font-medium text-gray-900">
                    {
                      <NumericFormat displayType="text" value={pv.price.cents / 100} prefix={'$'} decimalScale={2} fixedDecimalScale style={{ border: 'none', width: '100%' }} />
                    }
                  </p>

                  <div className="ml-4">
                    <ChangeQuantityComponent productVariant={pv} />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex items-center justify-between">
            <dt className="text-sm">Subtotal</dt>
            <dd className="text-sm font-medium text-gray-900">
              {<NumericFormat displayType="text" value={subtotal / 100} prefix={'$'} decimalScale={2} fixedDecimalScale style={{ border: 'none', width: '100%' }} />}
            </dd>
          </div>
          {shippingEnabled && (
            <div className="flex items-center justify-between">
              <dt className="text-sm">Shipping</dt>
              <dd className="text-sm font-medium text-gray-900">
                {shippingOption.cost_cents === 0 ? (
                  <dd className="text-sm font-medium text-gray-900">Free</dd>
                ) : (
                  <NumericFormat displayType="text" value={shippingOption.cost_cents / 100} prefix={'$'} decimalScale={2} fixedDecimalScale style={{ border: 'none', width: '100%' }} />
                )}
              </dd>
            </div>
          )}
          <div className="flex items-center justify-between">
            <dt className="text-sm">Fees</dt>
            <dd className="text-sm font-medium text-gray-900">
              {<NumericFormat displayType="text" value={fees / 100} prefix={'$'} decimalScale={2} fixedDecimalScale style={{ border: 'none', width: '100%' }} />}
            </dd>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
            <dt className="text-base font-medium">Total</dt>
            <dd className="text-base font-medium text-gray-900">
              {<NumericFormat displayType="text" value={total / 100} prefix={'$'} decimalScale={2} fixedDecimalScale style={{ border: 'none', width: '100%' }} />}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};


const ChangeQuantityComponent = ({ productVariant }) => {
  const { updateCartQuantity, state: { cart }, } = useCheckout();

  const [isChanging, setIsChanging] = useState(false);
  const [quantity, setQuantity] = useState(cart.quantity[productVariant.id] || 1);

  const handleUpdate = (q) => {
    if (q < 1) {
      updateCartQuantity(productVariant.id, 1);
      setQuantity(1);
    } else if (productVariant.unlimited_quantity) {
      updateCartQuantity(productVariant.id, q);
    } else if (q > productVariant.quantity) {
      updateCartQuantity(productVariant.id, productVariant.quantity);
      setQuantity(productVariant.quantity);
    } else {
      updateCartQuantity(productVariant.id, q);
      setQuantity(q);
    }

    setIsChanging(false);
  }

  return (
    <div className="flex flex-col  ">
      <label htmlFor={`quantity-${productVariant.id}`} className="text-sm font-medium text-gray-700 text-right">
        Quantity
      </label>
      {isChanging ? (
        <div className="flex justify-between">
          <button onClick={() => handleUpdate(quantity)} type="button" className="text-xs font-medium text-blue-700 hover:text-blue-500 hover:underline">Update</button>
          <input
            type="number"
            id={`quantity-${productVariant.id}`}
            name="quantity"
            className="ml-2 w-12 text-center rounded-md border border-gray-300"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>
      ) : (
        <div className="flex justify-between">
          <button onClick={() => setIsChanging(true)} type="button" className="text-xs font-medium text-blue-700 hover:text-blue-500 hover:underline">Change</button>
          <span className="ml-2 text-sm font-medium text-gray-700">{quantity}</span>
        </div>
      )}
    </div>
  )
}
// const OrderSummaryCard = () => {
// }
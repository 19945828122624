import React from 'react';
import { useForm } from './provider';

export const OfflinePaymentPage = (props) => {
   const { state, dispatch } = useForm();
  const onSubmit = (event) => {
    event.preventDefault();
    // dispatch({ type: "SET_LOADING", payload: true });
    // dispatch({ type: "SET_ERROR", payload: null });
    // dispatch({ type: "SET_SUCCESS", payload: null });
    // const { stripe, elements } = state;
    // singleChargeSubmit({ state, dispatch, stripe, elements });
  };

  return (
    <>
      <svg onClick={() => props.goToNamedStep("pick_payment_method")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
      </svg>


      <form onSubmit={onSubmit}>
        <label>
          Check Number
        </label>
        <input type={"text"} name={"checknumber"} />
        <button type='submit'>Submit</button>

      </form>
    </>
  )
}

import React from "react";

export const ProductHeading = ({ imageUrl, name, productType }) => {
  return (
    <div className="flex truncate">
      <div className="h-12 w-12 mr-2 flex-shrink-0">
        {!!imageUrl ? (
          <img
            className="w-full h-full object-cover rounded"
            src={imageUrl}
            alt=""
          />
        ) : (
          <span className="inline-flex items-center justify-center w-full h-full bg-gray-300 text-white rounded">
            {name[0]}
          </span>
        )}
      </div>

      <div style={{ minWidth: 0 }}>
        <h2 className="font-display leading-5 text-md font-semibold text-gray-800 truncate">
          {name}
        </h2>
        <span className="text-xs leading-5 text-gray-800">
          SKU: {productType}
        </span>
      </div>
    </div>
  );
};

export const ProductStats = ({
  transactionTotalDollarsHuman,
  transactionsCount,
}) => {
  return (
    <div className="flex justify-around mt-7 mb-10">
      <div className="mt-2 flex items-center text-sm leading-5 text-gray-700">
        <div className="w-10 h-10 mr-1 p-2 flex-shrink-0 rounded-md text-yellow-900 bg-yellow-100">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
        <div>
          <span className="text-lg font-semibold">
            {transactionTotalDollarsHuman}&nbsp;
          </span>{" "}
          <br />
          <span className="text-gray-500">raised</span>
        </div>
      </div>
      <div className="mt-2 flex items-center text-sm leading-5 text-gray-700">
        <div className="w-10 h-10 mr-1 p-2 rounded-md text-teal-500 bg-teal-200">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
          </svg>
        </div>
        <div>
          <span className="text-lg font-semibold">{transactionsCount}</span>
          <br />
          <span className="text-gray-500">
            {transactionsCount == 1 ? "donation" : "donations"}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ProductStatus = ({ status, path }) => {
  const classNames = (status) => {
    switch (status) {
      case "active":
        return " bg-green-100 text-green-800";
      case "inactive":
        return "bg-yellow-50 text-yellow-text";
      case "archived":
        return "bg-red-100 text-red-800";
      default:
        return " bg-green-100 text-green-800";
    }
  };

  return (
    <div className="flex justify-between">
      <span
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 ${classNames(
          status
        )}`}
      >
        {status}
      </span>
    </div>
  );
};

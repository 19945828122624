import React, { useState } from 'react'
// import { Dialog, Transition } from '@headlessui/react'
// import {
//   Bars3Icon,
//   CalendarIcon,
//   CogIcon,
//   HomeIcon,
//   MagnifyingGlassCircleIcon,
//   MapIcon,
//   MegaphoneIcon,
//   SquaresPlusIcon,
//   UserGroupIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
import SearchMembers from './SearchMembers'

export default function MembersListSidebar(props) {
  const { members, memberCount, currentMember, new_invoicing_member_path, fund } = props
  const [filteredMembers, setFilteredMembers] = useState(members || []);
  // let memberCount = Object.values(members).reduce((acc, val) => acc + val.length, 0);

  return (
    <>
      <div className="px-6 pt-6 pb-4">
        <div className="flex items-center justify-between">

          <div className="">
            <h2 className="text-lg font-medium text-gray-900">Members</h2>
            <p className="mt-1 text-sm text-gray-600">Search directory of {memberCount} Members</p>
          </div>
          <a href={new_invoicing_member_path}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12 text-indigo-600 hover:text-indigo-800">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
            </svg>
          </a>

        </div>
        <SearchMembers setFilteredMembers={setFilteredMembers} fund={fund} />
      </div>
      {/* Directory list */}
      <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
        {Object.keys(filteredMembers).length == 0 ? (
          <div className="px-6 py-4">
            <p className="text-sm text-gray-900">No members found.</p>
          </div>
        ) : (
          <>
            {Object.keys(filteredMembers).map((letter) => (
              <div key={letter} className="relative">
                <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                  <h3>{letter}</h3>
                </div>
                <ul role="list" className="relative z-0 divide-y divide-gray-200">
                  {filteredMembers[letter].map((fund_user) => (
                    <li key={fund_user.id}>
                      <div className={`relative bg-white flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-gray-50 ${fund_user.id == currentMember.id && "bg-gray-50"}`}>
                        <div className="flex-shrink-0">
                          {fund_user.user.imageUrl ? (
                            <img className="h-10 w-10 rounded-full" src={fund_user.user.imageUrl} alt="" />
                          ) : (
                            <span className="inline-flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full">
                              <span className="text-xl font-medium leading-none text-white">
                                {fund_user.user.first_name[0]}{fund_user.user.last_name[0]}
                              </span>
                            </span>
                          )}
                        </div>
                        <div className="min-w-0 flex-1">
                          <a href={fund_user.invoicing_member_path} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-sm font-medium text-gray-900"> {fund_user.user.first_name} {fund_user.user.last_name}
                              {fund_user.status == "archived" ? <span className="inline-block mx-1 bg-gray-200 rounded-full px-2 text-xs" aria-hidden="true">Archived</span> : ""}
                              {fund_user.overdue ? <span className="inline-block w-2.5 h-2.5 mx-1 rounded-full bg-red-600" aria-hidden="true"></span> : ""}
                              {fund_user.status == "paused" ? <span className="inline-block w-2.5 h-2.5 mx-1 rounded-full bg-yellow-600" aria-hidden="true"></span> : ""}
                            </p>
                            <p className="truncate text-sm text-gray-500">{fund_user.membership_id}</p>
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        )}
      </nav>
    </>
  )
}

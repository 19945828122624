import React, {
  useContext,
  createContext,
  useReducer,
} from "react";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeSDKLoader } from "../../utils/StripeSdkLoader";
import axios from "../../utils/requests";

const AddCardContext = createContext(null);

const initialState = {
  showCard: true,
  loading: false,
  error: false
}

const addCardReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SHOW_CARD":
      return { ...state, showCard: !state.showCard };
    case "SET_LOADING":
      return { ...state, loading: action.loading };
    case "SET_ERROR":
      return { ...state, error: action.error };
    default:
      return state;
  }
};

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: false,
  iconStyle: 'solid',
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Rubik", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6B6B6B',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [state, dispatch] = useContext(AddCardContext);

  const handleSubmit = async (event) => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    dispatch({ type: "SET_LOADING", loading: true });

    const card = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });

    if (error) {
      // Show error to your customer (for example, insufficient funds)
      dispatch({ type: "SET_ERROR", error: error.message });
      dispatch({ type: "SET_LOADING", loading: false });
    } else {
      // payment method created, now attach it to the customer.
      try {
        const response = await axios.post(
          state.create_card_response_url,
          {
            payment_method: paymentMethod
          }
        );

        window.location.reload();
      } catch (error) {
        dispatch({ type: "SET_ERROR", error: error.response.error });
      }

      dispatch({ type: "SET_LOADING", loading: false });

      // if (paymentMethod.status === 'succeeded') {
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      // }
    }
  }

  // const updateAmount = (value) => {
  //   dispatch({ type: "TOGGLE_SHOW_CARD" });
  // };

  return (
    <>
      <div className={`mb-5 col-span-1 rounded-lg shadow divide-gray-200 bg-white p-4`}>
        <label>
          Card Details
          <div className="p-3 my-2 border rounded ">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </label>
        <div className="flex justify-end">

          <button className="text-indigo-500 hover:text-indigo-700" disabled={state.loading} onClick={() => state.cancelAction()} >Cancel</button>
          <button className="ml-2 py-2 px-3 rounded bg-indigo-700 hover:bg-indigo-500 text-white" disabled={state.loading} onClick={(e) => handleSubmit(e)} >
            {state.loading ?
              <div role="status">
                <span className="">Loading...</span>
              </div>
              :
              <>
                Create Card
              </>
            }
          </button>
        </div>
      </div>
    </>
  )
}

const DashboardAddCardProvider = ({
  stripe_publishable_key,
  customer,
  cancelAction,
  create_card_response_url
}) => {
  const [store, dispatch] = useReducer(addCardReducer, {
    ...initialState,
    create_card_response_url: create_card_response_url,
    customer: customer,
    cancelAction: cancelAction
  });

  const value = React.useMemo(() => [store, dispatch], [store]);
  return (
    <AddCardContext.Provider value={value}>
      <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
        <StripeForm />
      </StripeSDKLoader>
    </AddCardContext.Provider>
  )
}

export default DashboardAddCardProvider
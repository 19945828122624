// Support component names relative to this directory:
// require('tailwindcss/tailwind.css');
// require('../styles/application.css');
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import * as Turbo from "@hotwired/turbo"
// require('@hotwired/turbo-rails')
// export { Turbo }

Rails.start()
// require('@rails/ujs').start();
ActiveStorage.start()
// require('@rails/activestorage').start();

var ReactRailsUJS = require('react_ujs');
var componentRequireContext = require.context('components', true);
// ReactUJS.getConstructor = ReactUJS.constructorFromRequireContext(require.context('components', true));

ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.handleEvent('turbo:render', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)

// document.addEventListener('turbo:frame-load', function (_e) {
//   console.log("turbo:frame-load");
//   ReactRailsUJS.mountComponents();
// }, false);

// var componentRequireContext = require.context('components', true);
// ReactRailsUJS.useContext(componentRequireContext);
// $(document).on("turbo:load", () => {
//   console.log("turbo!");
// });


// rich text editor
require('trix');
require('@rails/actiontext');

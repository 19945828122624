import React from "react"

import { InlineShareButtons } from "sharethis-reactjs";

// https://github.com/sharethis-github/sharethis-reactjs#readme


// this is a monkey patch of the share loader so the loader runs every time.
// turbolinks!!!
// load project wrapper
const load = function (component, product) {

  // load config
  let config = component.props.config || { enabled: true };
  config = JSON.parse(JSON.stringify(config));

  // load buttons
  const _onShareThisLoaded = window.onShareThisLoaded;
  let onload = () => {
    if (!onload.complete) {
      if (!config.id) {
        const id = 'sharethis-' + Date.now();
        config.id = id;
      }
      if (component.buttons.current) {
        component.buttons.current.id = config.id;
        window.__sharethis__.load(product, config);
      }
      if (_onShareThisLoaded) {
        _onShareThisLoaded();
      }
      onload.complete = true;
    }
  };
  window.onShareThisLoaded = onload;

  // load sharethis.js
  if (document.getElementById('sharethis-js')) {
    if (window.__sharethis__) {
      window.onShareThisLoaded();
    }
  } else {
    const script = document.createElement("script");
    script.setAttribute('id', 'sharethis-js');
    const params = {
      property: config.property || '',
      product: product,
      source: 'reactjs'
    }
    const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    script.src = "https://platform-api.sharethis.com/js/sharethis.js?" + query;
    script.async = true;
    document.body.appendChild(script);
  }
}


class MonkeyPatchButtons extends InlineShareButtons {
  componentDidMount() {
    load(this, 'inline-share-buttons');
  }
};

const ShareThis = (props) => {
  const { alignment, url, description, title, emailMessage, emailSubject, networks } = props

  return (
    <>
      <div>
        <MonkeyPatchButtons
          config={{
            alignment: alignment,  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'null',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: networks,
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40,             // the size of each button (INTEGER)

            // OPTIONAL PARAMETERS
            url: url, // (defaults to current url)
            // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
            description: description,       // (defaults to og:description or twitter:description)
            title: title,            // (defaults to og:title or twitter:title)
            message: emailMessage,     // (only for email sharing)
            subject: emailSubject,  // (only for email sharing)
            // username: 'custom twitter handle' // (only for twitter sharing)
          }}
        />
      </div>

    </>
  );
}

export default ShareThis

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { calcFee } from "../../utils/stripeFee";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({ due_levels, fee_model, selectedDueLevel, setSelectedDueLevel }) {
  const dueLevels = due_levels
  const [query, setQuery] = useState('')

  const filteredDueLevels =
    query === ''
      ? dueLevels
      : dueLevels.filter((dl) => {
        return dl.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <Combobox as="div" value={selectedDueLevel} onChange={setSelectedDueLevel}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">Assigned to</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(dueLevel) => {
            if (dueLevel) {
              return `$${calcFee(dueLevel.amount, fee_model, true).total} - ${dueLevel?.name}`
            }
          }
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredDueLevels.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredDueLevels.map((dl) => (<OptionItem
              due_level={dl}
              fee_model={fee_model}
              key={dl.id}
              value={dl}
            />
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

function OptionItem({ due_level, fee_model }) {
  const amount = calcFee(parseFloat(due_level.amount), fee_model, true)
  return (
    <Combobox.Option
      key={due_level.id}
      value={due_level}
      className={({ active }) =>
        classNames(
          'relative cursor-default select-none py-2 pl-3 pr-9',
          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
        )
      }
    >
      {({ active, selected }) => (
        <>
          <span className={classNames('block truncate', selected && 'font-semibold')}>${amount.total} - {due_level.name}</span>
          {selected && (
            <span
              className={classNames(
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-indigo-600'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  )

}
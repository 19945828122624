import React from 'react';
import { useFields } from './State';

import { DropDownOptions } from './DropDownOptions';

export const FieldSetContainer = (props) => {
  const { removeField, setFieldType, state } = useFields();
  const { field, index } = props;
  const namePrefix = `${state.resource_name}[form_fields_attributes][${index}]`;

  const handleRemoveClick = (e) => {
    e.preventDefault();
    removeField(field);
  };

  const handleInputTypeChange = (e) => {
    e.preventDefault();

    if (e.target.value === 'drop down') setFieldType(field, e.target.value);
  };

  return (
    <>
      {field.destroy ? (
        <>
          <input type="hidden" name={`${namePrefix}[_destroy]`} value="1" />
          <input type="hidden" name={`${namePrefix}[id]`} value={field.id} />
        </>
      ) : (
        <>
          {field.id ? (
            <input type="hidden" name={`${namePrefix}[id]`} value={field.id} />
          ) : (
            ''
          )}
          <div className="shadow sm:rounded-md sm:overflow-hidden my-5">
            <div className="grid grid-cols-8 gap-4 p-2 sm:p-3 bg-white">
              <div
                className={
                  field.input_type !== 'checkbox'
                    ? 'col-span-8 md:col-span-3'
                    : 'col-span-8 md:col-span-6'
                }
              >
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Field Name
                </label>
                <input
                  name={`${namePrefix}[label]`}
                  defaultValue={field.label}
                  disabled={field.disable_change}
                  placeholder={
                    field.input_type !== 'checkbox'
                      ? 'example: Union Member Code'
                      : 'example: Upon payment, I agree to our membership terms'
                  }
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-200"
                />
              </div>

              {field.input_type !== 'checkbox' ? (
                <div className="col-span-8 md:col-span-3">
                  <label className="block text-sm font-medium leading-5 text-gray-700">
                    Placeholder
                  </label>
                  <input
                    name={`${namePrefix}[placeholder]`}
                    defaultValue={field.placeholder}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="example: 123-1234A7"
                  />
                  <p className="mt-1 mb-2 text-xs leading-5 text-gray-500">
                    Optionally use this to show an example to help your users
                  </p>
                </div>
              ) : (
                ''
              )}

              <div className="col-span-8 md:col-span-2">
                <label className="block text-sm font-medium leading-5 text-gray-700">
                  Input Type
                </label>
                <select
                  name={`${namePrefix}[input_type]`}
                  defaultValue={field.input_type}
                  onChange={handleInputTypeChange}
                  className="mt-1 form-select block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                  {state.input_types.map((ip) => {
                    return (
                      <option value={ip} key={ip}>
                        {ip}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-span-8 flex justify-between">
                <div className="flex items-center">
                  <input
                    type="hidden"
                    name={`${namePrefix}[required]`}
                    value="false"
                  />
                  <input
                    id={`${field.objectId}Required`}
                    type="checkbox"
                    name={`${namePrefix}[required]`}
                    defaultChecked={field.required}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                  <label
                    htmlFor={`${field.objectId}Required`}
                    className="ml-2 block text-sm leading-5 text-gray-900"
                  >
                    {field.input_type === 'checkbox'
                      ? 'Require users check the box'
                      : 'Require users to fill out this field'}
                  </label>
                </div>

                <button
                  onClick={handleRemoveClick}
                  className=" px-2 py-1 bg-gray-50 hover:bg-red-600 text-red-600 hover:text-white transition duration-150 border border-gray-200 text-xs rounded-md"
                >
                  - Remove field
                </button>
              </div>

              {field.input_type === 'drop down' && (
                <DropDownOptions field={field} fieldIndex={index} />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

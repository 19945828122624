import React from "react";
import { generateId } from '../utils/genIds';


const SubscriptionMoverPicker = ({ subscription, due_levels, current_due_level }) => {
  const { id } = subscription;
  const [selectedDueLevel, setSelectedDueLevel] = React.useState(current_due_level);
  // const filteredDueLevels = due_levels.filter(due_level => due_level.id !== selectedDueLevel.id);
  const genId = generateId();
  const authenticity_token = document.querySelector('[name=csrf-token]').content;

  const handleChange = (e) => {
    const a = due_levels.find(due_level => due_level.id == e.target.value);
    setSelectedDueLevel(
      due_levels.find(due_level => due_level.id == e.target.value)
    );
  }


  return (
    <div>
      <form action={`/dashboard/reports/subscriptions/${id}`} method="post">
        <input type="hidden" name="_method" value="patch" autocomplete="off" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} autocomplete="off" />
        <label htmlFor={genId} className="block text-sm font-medium text-gray-700">
          Move Subscription to another Due Level
        </label>

        <select
          id={genId}
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          name="subscription[new_due_level_id]"
          value={selectedDueLevel.id}
          onChange={handleChange}>
          {due_levels.map(due_level => (
            <option key={due_level.id} value={due_level.id}>{due_level.name} - ${due_level.amount}</option>
          ))}
        </select>
        <button
          type="submit"
          data-confirm={`Are you sure you want to move this subscription from ${current_due_level.name} - $${current_due_level.amount} to ${selectedDueLevel.name} - $${selectedDueLevel.amount} ?`}
          className="inline-flex justify-center py-2 px-4 mt-3 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out" >
          Move Subscription
        </button>
      </form>

    </div>
  );
};



export default SubscriptionMoverPicker;
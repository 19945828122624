import React, { useState } from 'react';
import { createPaymentMethod, useForm } from './provider';
import { CardElement } from '@stripe/react-stripe-js';
import axios from "../../utils/requests";

const CARD_OPTIONS = {
  hidePostalCode: false,
  iconStyle: 'solid',
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Rubik", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6B6B6B',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

export const NewPaymentMethodSection = (props) => {
  const { state, dispatch, stripe, elements } = useForm();
  const { errors } = state;
  const [errorClass, setErrorClass] = useState('');

  const handleChange = (event) => {
    // could show the error here if we wanted to
    const { error } = event;

    if (!!error) {
      setErrorClass('border-red-600');
    } else {
      setErrorClass('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: "TOGGLE_LOADING" });
    const resp = await createPaymentMethod({ state, dispatch, stripe, elements });
    // return early and show errors if payment method doesnt create on stripe
    if (resp.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [resp.error.message] },
      });
      return;
    }

    try {
      await axios.post(
        state.create_card_path,
        {
          payment_method: resp.paymentMethod
        }
      );
    } catch (error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({ type: "SET_ERRORS", errors: { base: error.response.data.error } });
      return
    }
    dispatch({ type: "TOGGLE_LOADING" });
    // set payment method id on the state so we dont do it again.
    dispatch({
      type: "SET_PAYMENT_METHOD",
      paymentMethod: resp.paymentMethod,
    });

    // go to the create payment step
    props.goToNamedStep('card_payment_page')
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="my-6">
        <div className="grid sm:grid-cols-4 gap-2">

          <div className={`p-3 border rounded-md sm:col-span-3 ${errorClass}`}>
            <CardElement options={CARD_OPTIONS} onChange={handleChange} />
          </div>
          <SubmitBtn />
        </div>
        {errors && errors.base && <div className="text-red-600 text-center">{errors.base}</div>}
      </form>
    </>
  )

}

const SubmitBtn = () => {
  const { state, stripe } = useForm();
  const { amount, loading } = state;

  const btnLoading = !stripe || loading;
  let buttonStyles = "w-full text-center py-3 border border-transparent leading-4 rounded-md text-white ease-in-out transition focus:outline-none "
  if (btnLoading || amount < 1 || isNaN(amount)) {
    buttonStyles = buttonStyles.concat("bg-indigo-200")
  } else {
    buttonStyles = buttonStyles.concat("bg-indigo-600 hover:bg-indigo-800 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 duration-150")
  }

  const buttonText = "Save"

  return (
    <button
      type="submit"
      disabled={btnLoading || amount < 1}
      className={buttonStyles}
    >
      {btnLoading ? "Loading…" : buttonText}
    </button>
  );
};

export const CreatePaymentMethodPage = (props) => {
  return (
    <>
      <svg onClick={() => props.goToNamedStep("pick_payment_method")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
      </svg>
      <h5 className="text-2xl text-center text-gray-700 ">
        Create Card
      </h5>
      <NewPaymentMethodSection goToNamedStep={props.goToNamedStep} />
    </>
  )
}
import React from 'react';
import ReactToolTip from 'react-tooltip';
import { generateId } from '../utils/genIds';

const FormInput = (props) => {
  const {
    helperText,
    label,
    description,
    errors = [],
    modelName,
    model,
    attribute,
    type,
    options = [],
    value,
    setValue,
    required,
    toolTip,
  } = props;

  const inputMap = {
    textarea: TextArea,
    select: Select,
    checkbox: Checkbox,
    default: Input,
  };

  const id = generateId();
  const name = `${modelName}[${attribute}]`;

  let handleChange = null;
  if (typeof setValue === 'function') {
    handleChange = (e) => setValue(e.target.value);
  }

  let fundsTypeOptions = [];
  if (type === 'select') {
    fundsTypeOptions = Object.keys(options).map((e, i) => {
      return (
        <option key={i} value={options[e]}>
          {e.split('_').join(' ')}
        </option>
      );
    });
  }

  let componentProps = {
    required,
    id,
    name,
    defaultValue: value || model[attribute],
    onChange: handleChange,
  };
  switch (type) {
    case 'select':
      componentProps = { ...componentProps, fundsTypeOptions };
      break;
    case 'textarea':
      break;
    default:
      componentProps = { ...componentProps, type };
  }

  const InputComponent = inputMap[type] || inputMap['default'];

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
        {!!required && '*'}
        {!!toolTip && <ToolTip dataForId={id} dataTip={toolTip} />}
      </label>
      {!!description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      <div className={`mt-1 relative rounded-md ${type === "checkbox" ? "" : "shadow-sm"}`}>
        <InputComponent {...componentProps} />
        {errors.length > 0 ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : (
          ''
        )}
      </div>
      {errors.map((e, i) => {
        return (
          <p key={i} className="mt-2 text-sm text-red-600">
            {e}
          </p>
        );
      })}
      {helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
    </>
  );
};

const Select = (props) => {
  const {
    required,
    id,
    name,
    defaultValue,
    onChange,
    fundsTypeOptions,
  } = props;

  return (
    <select
      required={required}
      id={id}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-indigo-500 "
    >
      {fundsTypeOptions}
      // would like to refactor to only show non-dues. And be hidden and
      auto-select dues when applicable
    </select>
  );
};

const TextArea = (props) => {
  const { required, id, name, defaultValue, onChange } = props;

  return (
    <textarea
      required={required}
      id={id}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      className="form-input block w-full focus:ring-indigo-500  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
    />
  );
};


const Checkbox = (props) => {
  const { required, id, name, defaultValue } = props;
  return (
    <div className="flex items-center">
      <input
        type="hidden"
        name={name}
        value="false"
      />
      <input
        required={required}
        id={id}
        type="checkbox"
        name={name}
        defaultChecked={defaultValue}
        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
      />
      <label
        htmlFor={id}
        className="ml-2 block text-sm leading-5 text-gray-900"
      ></label>
    </div>
  );
}

const Input = (props) => {
  const { required, id, type, name, defaultValue, onChange } = props;

  return (
    <input
      required={required}
      id={id}
      type={type}
      name={name}
      step={type == "number" ? "0.01" : null}
      defaultValue={defaultValue}
      onChange={onChange}
      className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 disabled:opacity-50 focus:ring-indigo-500"
    />
  );
};

export const ToolTip = (props) => {
  const { dataForId, dataTip } = props;

  return (
    <>
      <span data-for={dataForId} data-tip={dataTip} className="ml-1">
        <svg viewBox="0 0 20 20" className="w-4 h-4 inline-block">
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <ReactToolTip
          multiline
          id={dataForId}
          type="info"
          place="top"
          effect="solid"
        />
      </span>
    </>
  );
};

export default FormInput;

import React from 'react';
import { FieldsProvider, useFields } from './State';
import { FieldSetContainer } from './FormFields';

export default (props) => {
  const { resource, resource_name, fields, dropdown_options, input_types } = props;

  return (
    <FieldsProvider
      resource={resource}
      resource_name={resource_name}
      fields={fields}
      dropdown_options={dropdown_options}
      input_types={input_types}
    >
      <div className="mt-8 pt-8 border-t flex justify-between">
        <div>
          <h3 className="text-xl leading-6 font-medium text-indigo-800">
            Customize Your Form
          </h3>
          <p className="mt-1 mb-2 text-sm leading-5 text-gray-500">
            Add unique form fields for gathering custom data from your
            members and clients
          </p>
        </div>
        <AddFieldButton />
      </div>
      <FieldsListContainer />
    </FieldsProvider>
  );
};

const FieldsListContainer = () => {
  const { state } = useFields();
  return (
    <>
      {state.fields.map((field, index) => {
        return (
          <FieldSetContainer key={field.objectId} field={field} index={index} />
        );
      })}
    </>
  );
};

const AddFieldButton = () => {
  const { addField } = useFields();

  const handleClick = (e) => {
    e.preventDefault();
    addField();
  };

  return (
    <>
      <span className="mt-3 inline-flex rounded-md shadow-sm">
        <button
          onClick={handleClick}
          className="relative inline-flex items-center py-2 px-4 bg-white hover:bg-indigo-500 border border-indigo-500 rounded-md text-sm leading-5 font-medium text-indigo-700 hover:text-white focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
        >
          + Add Field
        </button>
      </span>
    </>
  );
};

import React from "react";
import { useForm, singleChargeSubmit } from "./provider";
// import { PaymentMethodSection } from "./StripeComponents";
import axios from "../../utils/requests";
import { prettyDate } from "../../utils/formatters";


export const CardPaymentPage = (props) => {
  const { state, dispatch, chargeData, stripe, elements } = useForm();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const resp = await singleChargeSubmit({ dispatch, state, chargeData, stripe, elements, })

    if (resp.success) {
      // go to the create payment step
      props.goToNamedStep('success_page')
    }
    if (resp.error) {
      dispatch({ type: "SET_ERRORS", errors: { base: resp.error.response.data.error } });
    }
  };

  const handleBackClick = () => {
    dispatch({ type: "SET_OFFLINE_PAYMENT", offlinePayment: false })
    props.goToNamedStep("pick_payment_method")
  }
  return (
    <>
      <svg onClick={handleBackClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
      </svg>
      <h5 className="text-2xl text-center text-gray-700  mb-3">
        Confirm Payment
      </h5>
      <form onSubmit={handleSubmit}>
        <div className=" hidden space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
          <p>pay over due or pay full amount?</p>
        </div>
        <Summary />
        {state.errors && state.errors.base && <div className="text-red-600 text-center">{state.errors.base}</div>}
        {state.offlinePayment && <OfflinePaymentNotes />}
        <LegalCopy />
        <SubmitBtn />
      </form>
    </>
  );
};

const OfflinePaymentNotes = () => {
  const { state, dispatch } = useForm();
  const { notes } = state;

  const handleChange = (e) => {
    dispatch({ type: "SET_NOTES", notes: e.target.value })
  }

  return <>
    <label htmlFor="notes" className="form-label">Notes</label>
    <p className="text-xs text-gray-400">(IE: check number)</p>
    <input id="notes" type="text" className="form-input" value={notes} onChange={handleChange} />
  </>
}

const SubmitBtn = () => {
  const { state, chargeData, stripe } = useForm();
  const { fund, loading } = state;
  const currentChargeData = chargeData()

  const btnLoading = !stripe || loading;
  let buttonStyles = "w-full mt-2 text-center text-xl px-3 py-4 mx-auto border border-transparent text-md leading-4 font-body font-medium rounded-md text-white ease-in-out transition focus:outline-none "
  if (btnLoading || currentChargeData.amount < 1 || isNaN(currentChargeData.amount)) {
    buttonStyles = buttonStyles.concat("bg-indigo-200")
  } else {
    buttonStyles = buttonStyles.concat("bg-indigo-600 hover:bg-indigo-800 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 duration-150")
  }

  // const buttonText = fund.form_submit_text;
  const buttonText = `Pay ${currentChargeData.amount > 0 ? '$' + currentChargeData.total : ""} ${fund.amount_currency != "USD" ? fund.amount_currency : ""}`

  return (
    <button
      type="submit"
      disabled={btnLoading || currentChargeData.amount < 1}
      className={buttonStyles}
    >
      {btnLoading ? "Loading…" : buttonText}
    </button>
  );
};

const Summary = () => {
  const { state, setInvoices, chargeData } = useForm();
  const { invoices, fund } = state;
  const currentChargeData = chargeData()

  return (
    <>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Title
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Due Date
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right">
                    Amount Due
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <input
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        type="checkbox"
                        checked={invoice.checked}
                        onChange={() => setInvoices(invoice.id)} />

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.title}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prettyDate(invoice.due_date)}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      {invoice.amount_due_cents > 0 ? `$` + (invoice.amount_due_cents / 100).toFixed(2) : ""}
                      {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <dl className="space-y-6 border-t border-gray-200 py-6">
        <div className="flex items-center justify-between">
          <dt className="text-sm">Subtotal</dt>
          <dd className="text-sm font-medium text-gray-900">
            {currentChargeData.amount > 0 ? `$` + currentChargeData.amount.toFixed(2) : ""}
            {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
          </dd>
        </div>

        <div className="flex items-center justify-between">
          <dt className="text-sm"> Processing Fee</dt>
          <dd className="text-sm font-medium text-gray-900">
            {currentChargeData.amount > 0 ? `$` + currentChargeData.totalFee : ""}
            {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
          </dd>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
          <dt className="text-base font-medium">Total</dt>
          <dd className="text-base font-medium text-gray-900">
            {currentChargeData.amount > 0 ? `$` + currentChargeData.total : ""}
            {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
          </dd>
        </div>
      </dl>
    </>
  );
};

const LegalCopy = () => {
  const { autoPay } = useForm();
  return (
    <>
      <aside className="mt-5 text-xs text-gray-600">
        <span>
          By confirming your {autoPay ? "subscription" : "transaction"}, you
          allow Unionly to charge your card for this amount{" "}
          {autoPay ? "and future payments for the same amount " : ""}in
          accordance with their{" "}
        </span>
        <a className="underline" href="/terms-of-use">
          terms
        </a>{" "}
        and{" "}
        <a className="underline" href="/privacy-policy">
          privacy policy
        </a>{" "}
        .
      </aside>
    </>
  );
};


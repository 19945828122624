import React from 'react';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/react';
import FileArea from './FileArea';
import FileField from './FileField';
import FormInput from './FormInput';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MemberFormTabs({ memberForm, fund, memberFormErrors, errors, exampleSpreadsheetPath, invoicingMembersPath, importInvoicingMembersPath }) {
  return (
    <div className="mt-10">
      <TabGroup>
        <div className="border-b border-gray-200">
          <TabList className="-mb-px flex space-x-8 justify-center">
            <Tab className="
          data-[selected]:border-indigo-500
          data-[selected]:text-indigo-600
          data-[hover]:border-gray-300
          data-[hover]:text-gray-700
          border-transparent text-gray-500
          whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium
          ">
              Add Single Member
            </Tab>
            <Tab className="
          data-[selected]:border-indigo-500
          data-[selected]:text-indigo-600
          data-[hover]:border-gray-300
          data-[hover]:text-gray-700
          border-transparent text-gray-500
          whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium
          ">
              Upload Spreadsheet
            </Tab>
          </TabList>
        </div>
        <TabPanels>
          <TabPanel className="px-4 py-5 sm:p-6 bg-white rounded-lg">
            <NewMemberForm memberForm={memberForm} invoicingMembersPath={invoicingMembersPath} fund={fund} memberFormErrors={memberFormErrors} errors={errors} />
          </TabPanel>
          <TabPanel className="px-4 py-5 sm:p-6 bg-white rounded-lg">
            <UploadForm fund={fund} errors={errors} exampleSpreadsheetPath={exampleSpreadsheetPath} importInvoicingMembersPath={importInvoicingMembersPath} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
}


const NewMemberForm = ({ memberForm, invoicingMembersPath, fund, memberFormErrors }) => {

  return (
    <div className="font-body max-w-3xl mx-auto mt-8 mb-20">
      <form action={invoicingMembersPath} method="post" encType="multipart/form-data">
        <input type="hidden" name="authenticity_token" value={document.getElementsByName("csrf-token")[0].content} />
        <div className="mt-8">
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="col-span-1 sm:col-span-3">
              <FormInput
                modelName="user"
                model={memberForm}
                attribute="first_name"
                label="First Name"
                type="text"
                required={true}
                errors={memberFormErrors.messages && memberFormErrors.messages["first_name"]}
              />
            </div>
            <div className="col-span-1 sm:col-span-3">
              <FormInput
                modelName="user"
                model={memberForm}
                attribute="last_name"
                label="Last Name"
                type="text"
                required={true}
                errors={memberFormErrors.messages && memberFormErrors.messages["last_name"]}
              />
            </div>
            <div className="col-span-1 sm:col-span-3">
              <FormInput
                modelName="user"
                model={memberForm}
                attribute="email"
                label="Email"
                type="text"
                required={true}
                errors={memberFormErrors.messages && memberFormErrors.messages["email"]}
              />
            </div>
            <div className="col-span-1 sm:col-span-3">
              <FormInput
                modelName="user"
                model={memberForm}
                attribute="membership_id"
                label="Membership ID"
                type="text"
                required={true}
                errors={memberFormErrors.messages && memberFormErrors.messages["membership_id"]}
              />
            </div>
            {fund.dues_calculation_type == 'indivitual' && (
              <div className="col-span-1 sm:col-span-3">
                <FormInput
                  className="col-span-1 sm:col-span-3"
                  modelName="user"
                  model={memberForm}
                  attribute="dues_amount"
                  label="Monthly Dues Amount"
                  type="number"
                  required={true}
                  errors={memberFormErrors.messages && memberFormErrors.messages["dues_amount"]}
                />
              </div>
            )}
            <div className="col-span-1 sm:col-span-3">
              <FormInput
                className="col-span-1 sm:col-span-3"
                modelName="user"
                model={memberForm}
                attribute="balance"
                label="Balance"
                description="This is the amount of money that the member owes to date."
                type="number"
                errors={memberFormErrors.messages && memberFormErrors.messages["balance"]}
              />
            </div>
            <div className="hidden sm:block sm:col-span-6"></div>
          </div>
        </div>
        <div className="font-body pt-5">
          <div className="flex justify-end">
            <span className="inline-flex rounded-md shadow-sm">
              <a href={invoicingMembersPath}
                className="py-2 px-4 bg-white border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                Cancel
              </a>
            </span>
            <span className="ml-3 inline-flex rounded-md shadow-sm">
              <button type="submit"
                className="inline-flex justify-center py-2 px-20 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                Add Member
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>

  );
}

const UploadForm = ({ errors, exampleSpreadsheetPath, importInvoicingMembersPath }) => {
  return (
    <div className="font-body max-w-3xl mx-auto mb-20 border-gray-300">
      <div className="flex">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mt-3 mr-3 h-12 w-12 text-gray-400">
          <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
        </svg>
        <div>
          <h2 className="mt-4 text-2xl font-medium text-gray-900 ">Upload members via spreadsheet</h2>
          <p className="mt-1 text-sm text-gray-500">Upload a spreadsheet of your members with the required headers below in order to import your members.  </p>
          <span className="text-sm text-gray-500">Download our spreadsheet template here:
            <a href={`${exampleSpreadsheetPath}.xlsx`} className="text-blue-500">example.xlsx</a>
          </span>
        </div>
      </div>
      <form action={importInvoicingMembersPath} method="post" encType="multipart/form-data">
        <input type="hidden" name="authenticity_token" value={document.getElementsByName("csrf-token")[0].content} />
        <FileArea
          name="spreadsheet"
          initialValue={null}
          hasMaxSize={false}
          buttonText="Drag & Drop Spreadsheet Here"
          previewType="name"
          errors={errors || []}
        />
        <label htmlFor="create_invoices">
          <input type="hidden" name="create_invoices" value="false" />
          <input id="create_invoices" type="checkbox" name="create_invoices" value="true" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
          Create Invoices?
        </label>
        <div>
          <label htmlFor="send_emails">
            <input type="hidden" name="send_emails" value="false" />
            <input id="send_emails" type="checkbox" name="send_emails" value="true" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
            Send Emails?
          </label>
        </div>
        <p className="text-sm text-gray-500 italic"> (required fields are: Membership ID, First Name, Last Name, Email, Dues Amount) </p>
        <div className="flex justify-end">
          <button type="submit" className="inline-flex justify-center self-end py-2 px-5 mt-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">Upload Members</button>
        </div>
        <div className="hidden">
          <FileField
            name="spreadsheet"
            initialValue={null}
            hasMaxSize={false}
            buttonText="Select Spreadsheet "
            previewType="name"
            errors={errors || []} />
        </div>
      </form>
    </div>
  );
}
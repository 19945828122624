import React, { useState, useRef } from 'react';
// import { QRCodeCanvas } from "qrcode.react"
// import html2canvas from "html2canvas"
import { QRCode } from 'react-qrcode-logo';



export default function ({ url, logoUrl }) {
  // const url = props.url
  const downloadCode = () => {
    const canvas = document.getElementById("code_id");

    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl
      downloadLink.download = `your_name.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  return (
    <div>
      <div className="flex justify-center rounded-md overflow-hidden shadow ">

        <QRCode
          id="code_id"
          value={url}
          logoImage={logoUrl}
          size={300}
          qrStyle='dots'
          removeQrCodeBehindLogo={true}
          eyeRadius={10}
          quietZone={10}
          logoWidth={50}
          logoHeight={50}
          logoOpacity={1}

        />
      </div>

      <div className="flex justify-center my-4">
        <button class="btn-primary" onClick={downloadCode}>
          Download QR Code
        </button>
      </div>
    </div>
  );
};

// export default function (props) {
//   const url = props.url

//   const getCanvas = () => {
//     const qr = document.getElementById("fancy-qr-code")
//     if (!qr) return

//     return html2canvas(qr, {
//       onclone: snapshot => {
//         const qrElement = snapshot.getElementById("fancy-qr-code")
//         if (!qrElement) return
//         // Make element visible for cloning
//         qrElement.style.display = "block"
//       },
//     })
//   }

//   const downloadQRCode = async () => {
//     const canvas = await getCanvas()
//     if (!canvas) throw new Error("<canvas> not found in DOM")

//     const pngUrl = canvas
//       .toDataURL("image/png")
//       .replace("image/png", "image/octet-stream")
//     const downloadLink = document.createElement("a")
//     downloadLink.href = pngUrl
//     downloadLink.download = "QR code.png"
//     document.body.appendChild(downloadLink)
//     downloadLink.click()
//     document.body.removeChild(downloadLink)
//   }

//   return (
//     <div id="fancy-qr-code">
//       <QRCodeCanvas
//         id="fancy-qr-code"
//         level="H"
//         size={300} value={url}
//         imageSettings={{
//           src: "https://static.zpao.com/favicon.png",
//           x: undefined,
//           y: undefined,
//           height: 50,
//           width: 50,
//           excavate: true,
//         }}
//       />
//       <button onClick={downloadQRCode}>Download QR Code</button>
//     </div>
//   );
// };

// export default QRCode;

import React, { useState } from 'react';
import FormInput from './FormInput';
import NestedDueLevelsFields from './NestedDueLevelsFields';

const FundTypePicker = (props) => {
  const [fundType, setFundType] = useState(props.model.fund_type);

  return (
    <>
      <FormInput {...props} value={fundType} setValue={setFundType} />
      {fundType === 'dues' ? (
        <NestedDueLevelsFields
          fund={props.model}
          errors={props.errors}
          dueLevels={props.due_levels}
          dueLevelStatuses={props.due_level_statuses}
          intervalOptions={props.interval_options}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default FundTypePicker;

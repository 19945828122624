import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { updateFundPositions } from "../../utils/requests";
const FundsContext = createContext(null);

const initialState = {
  funds: [],
  draggable: false,
};

export const FundsProvider = (props) => {
  const { funds = [] } = props;
  const state = {
    ...initialState,
    funds,
  };

  const [store, dispatch] = useReducer(fundsReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <FundsContext.Provider value={value} {...props} />;
};

const fundsReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FUNDS":
      return { ...state, funds: action.funds };
    case "TOGGLE_DRAG":
      return { ...state, draggable: !state.draggable };
    default:
      return state;
  }
};

export const useFunds = () => {
  const [state, dispatch] = useContext(FundsContext);

  const moveFund = useCallback(
    (dragIndex, hoverIndex) => {
      const dragFund = state.funds[dragIndex];
      // const hoverFund = stat.funds[hoverIndex]
      const newFunds = [...state.funds];

      // first remove the item at dragIndex (the fund that is being dragged)
      // then insert the dragFund at the fund that is being hovered over
      newFunds.splice(dragIndex, 1);
      newFunds.splice(hoverIndex, 0, dragFund);

      // update position for the fund we dragged
      const sortedFunds = newFunds.map((f, i) => {
        if (f.id != dragFund.id) return f;

        const position =
          dragIndex > hoverIndex
            ? f.position - (dragIndex - hoverIndex)
            : f.position + (hoverIndex - dragIndex);

        return { ...f, position };
      });

      dispatch({ type: "UPDATE_FUNDS", funds: sortedFunds });
    },
    [state.funds]
  );

  const updatePositions = async (fundItem) => {
    const fund = state.funds.find((f) => f.id == fundItem.id);

    try {
      await updateFundPositions({
        fund: { id: fund.id, position: fund.position },
      });
    } catch (error) {
    }
  };

  const toggleDrag = () => {
    dispatch({ type: "TOGGLE_DRAG" });
  };

  return {
    state,
    dispatch,
    useDrag,
    useDrop,
    moveFund,
    updatePositions,
    toggleDrag,
  };
};
